.notification-container {
  .plt-contents-desc {
    max-width: 512px;
  }
  .plt-content-block {
    max-width: 733px;
  }
  .notitication-check {
    margin-left: auto;
  }
}
