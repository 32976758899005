.plt-icon-box {
  width: 100%;
  flex-direction: column;
  background: var(--plt-background-tertiary);
  border-radius: var(--plt-border-radius-300);
  transition: all .35s;
  .icon-image{
    min-width: var(--dss-size-800);
    max-width: var(--dss-size-800);
    min-height: var(--dss-size-800);
    max-height: var(--dss-size-800);
  }
  .plt-badge{
    background: #1717170D;
  }
  &-txt{
    gap: var(--plt-space-200);
  }
  &-download {
    min-width: var(--dss-size-800);
    max-width: var(--dss-size-800);
    min-height: var(--dss-size-800);
    max-height: var(--dss-size-800);
    // display: none;
  }
  // &:hover {
  //   .plt-icon-box-download {
  //     display: block;
  //   }
  // }
}

