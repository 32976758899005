/* Use a more-intuitive box-sizing model. */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin and padding and reset fonts */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

html {
  font-size: var(--reset-rem-size);

  @include mq(phone) {
    --reset-rem-size: 14px;
  }
}

* {
  font-family: var(--plt-font-family-primary);
  line-height: 1.3;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  @include plt-custom-scroll;
}

/* Improve media defaults */
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

/* Avoid text overflows */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  overflow-wrap: break-word;
}

/* Remove built-in form typography styles */
input,
button,
textarea,
select {
  font: inherit;
}

/* Create a root stacking context */
#root,
#__next {
  isolation: isolate;
}

input {
  &[type="number"] {
    appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: $black;
    box-shadow: 0 0 0px 1000px $white inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &[type="search"]::-webkit-search-decoration,
  &[type="search"]::-webkit-search-cancel-button,
  &[type="search"]::-webkit-search-results-button,
  &[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

ul,
ol {
  padding: 0;
  margin: 0;

  &[role="list"] {
    list-style: none;
  }
}

blockquote,
q {
  quotes: none;

  &:before,
  &:after {
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

pre {
  font-family: $base-font-family;
  tab-size: 2;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms;
    animation-iteration-count: 1;
    transition-duration: 0.01ms;
    scroll-behavior: auto;
  }
}

/* smooth scroll */
* {
  scroll-behavior: smooth;
  scroll-padding-top: 15px;
}
