.plt-plan-box {
  // border: 1px solid var(--plt-border-secondary);
  // border-radius: var(--plt-border-radius-300);
  // .plt-plan-btm {
  //   border-top: 1px solid var(--plt-border-secondary);
  //   background: var(--plt-background-tertiary);
  // }
  .plan-wrapper {
    border-radius: var(--plt-border-radius-300) var(--plt-border-radius-300) 0 0;
    border-bottom: 1px solid var(--plt-border-secondary);
    .plan-detail-content {
      gap: var(--plt-space-1200);
    }
    
  }
  .plan-detail-btn {
    margin-left: auto;
  }
}

.plan-steps-wrapper {
  gap: var(--plt-space-1200);
  .plan-steps-img {
    min-width: var(--plt-size-1000);
    max-width: var(--plt-size-1000);
    min-height: var(--plt-size-1000);
    max-height: var(--plt-size-1000);
  }
}

.plt-payment-box {
  max-width: 720px;
  .payment-img {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 70px;
    max-width: 70px;
    min-height: 36px;
    max-height: 36px;
    border: 1px solid var(--plt-border-secondary);
    border-radius: var(--plt-border-radius-100);
  }
  .plt-badge  {
    border-radius: var(--plt-border-radius-700);
  }
  .payment-delete {
    margin-left: auto;
    svg * {
      stroke: var(--plt-icon-negative);
    }
  }
}


.plt-invoices-box {
  .plt-table-container{
    max-width: 100%;
    table {
      tr,th {
        background: transparent;
      }
      td {
        vertical-align: middle;
      }
      // tbody tr {
      //   border: 1px solid var(--plt-border-secondary);
      // }
    }
  }
}
