.plt-table-container {
  width: 100%;
  max-width: 860px;
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;

    tr,
    td,
    th {
      text-align: left;
      background: var(--plt-background-tertiary);
    }

    th {
      // min-width: 160px;
      padding: var(--plt-space-100) var(--plt-space-400);
      color: var(--plt-text-tertiary);
      font-size: var(--plt-font-size-300);
      font-weight: var(--plt-font-weight-regular);
      line-height: var(--plt-line-height-120);
    }

    td {
      padding: var(--plt-space-400);
      color: var(--plt-text-secondary);
      font-size: var(--plt-font-size-200);
      font-weight: var(--plt-font-weight-regular);
      line-height: var(--plt-line-height-150);
      vertical-align: top;
      &:first-child {
        min-width: 266px;
      }
    }

    tr td:first-child {
      border-top-left-radius: var(--plt-border-radius-300);
      border-bottom-left-radius: var(--plt-border-radius-300);
    }
    tr td:last-child {
      border-top-right-radius: var(--plt-border-radius-300);
      border-bottom-right-radius: var(--plt-border-radius-300);
    }

    .plt-iconbtn {
      background: var(--plt-background-surface);
      transition: all 0.35s;
      &:hover {
        background: var(--plt-background-tertiary-hover);
      }
    }
  }
}
.plt-table-wrapper {
  overflow: auto;
}

.plt-scale-table-container {
  max-width: 835px;
}

/* adding space to all the cells */
.plt-scale-table th {
  padding: var(--plt-space-200) var(--plt-space-400);
  color: var(--plt-text-tertiary);
  font-size: var(--plt-font-size-300);
  font-weight: var(--plt-font-weight-regular);
  line-height: var(--plt-line-height-120);
}

.plt-scale-table td {
  padding: var(--plt-space-300) var(--plt-space-400);
  font-size: var(--plt-font-size-200);
  font-weight: var(--plt-font-weight-regular);
  color: var(--plt-text-secondary);
  line-height: var(--plt-line-height-120);
}
.plt-scale-table td,
.plt-heading-usage,
.plt-heading-info {
  font-size: var(--plt-font-size-200);
  font-weight: var(--plt-font-weight-regular);
  color: var(--plt-text-secondary);
  line-height: var(--plt-line-height-120);
}
/*end of adding space to all the cells */

/* basic table styles */
.plt-scale-table {
  width: 100%;
}
.plt-scale-table,
.plt-scale-table td,
.plt-scale-table tr,
.plt-scale-table th {
  border-collapse: collapse;
  border: 1px solid var(--plt-border-secondary);
  border-radius: var(--plt-border-radius-200);
  text-align: left;
  vertical-align: top;
}

.plt-scale-table th {
  &:first-child {
    border-top-left-radius: var(--plt-border-radius-200);
  }
  &:last-child {
    min-width: 235px;
  }
}

.plt-scale-table td {
  width: 200px;
  .plt-heading-text {
    color: var(--plt-text-secondary);
  }
}

.plt-scale-table tr:hover {
  .plt-table-hover-icon {
    opacity: 1;
    visibility: visible;
  }
}

.plt-table-container table tr:hover {
  .plt-table-hover-icon {
    opacity: 1;
    visibility: visible;
  }
}
