.custom-radius-wrapper {
  margin:  0 var(--plt-space-600);
  // height: 0;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all .35s;
  &.active {
    opacity: 1;
    max-height: max-content;
  }
}
.custom-radius-detail {
  max-width: 174px;
  flex-wrap: wrap;
  row-gap: var(--plt-space-400);
  margin-top: var(--plt-space-400);
  transition: all .35s;
  .plt-select-dropdown-wrapper {
    min-width: 75px;
    max-width: 75px;
  }
  .modal-value-block {
    display: flex;
    align-items: center;
    height: 48px;
    padding: var(--plt-space-200) var(--plt-space-300);
    border: var(--plt-border-width-100) solid var(--plt-border-secondary);
    // border: var(--plt-border-width-100) solid var(--plt-border-input);
    border-radius: var(--plt-border-radius-100);
  }
  .dss-border-radius {
    width: 16px;
    height: 16px;
    display: block;
    &-top-lft {
      border-top: 2px solid black;
      border-left: 2px solid black;
    }
    &-top-rgt {
      border-top: 2px solid black;
      border-right: 2px solid black;
    }
    &-btm-lft {
      border-bottom: 2px solid black;
      border-left: 2px solid black;
    }
    &-btm-rgt {
      border-bottom: 2px solid black;
      border-right: 2px solid black;
    }
    &-value {
      display: flex;
      width: 24px;
      border: none;
      color: var(--dss-text-tertiary);
      font-size: var(--dss-body-compact-sm-font-size);
      font-weight: var(--dss-body-compact-sm-font-weight);
      line-height: var(--dss-body-compact-sm-line-height);
      font-family: var(--dss-body-compact-sm-font-family);
      &::placeholder {
        color: var(--dss-text-tertiary);
        font-size: var(--dss-body-compact-sm-font-size);
        font-weight: var(--dss-body-compact-sm-font-weight);
        line-height: var(--dss-body-compact-sm-line-height);
        font-family: var(--dss-body-compact-sm-font-family);
      }
      &:focus-visible {
        border: none;
        outline: none;
      }
      
    }
  }
}

.plt-iconbtn.custom-radius-btn {
  border-radius: var(--plt-border-radius-100);
}