.tab-content-body {
  // min-height: 100vh;
  // flex: 1 1 auto;

  flex: 1 1 0;
  min-width: 0;
  min-height: 100dvh;
  max-height: 100dvh;
  overflow-y: auto;
  scrollbar-color: light;
  scrollbar-width: thin;
  scrollbar-color: var(--dss-scroll-color) rgba(0, 0, 0, 0);
  transition: all 0.35s;
  &.active {
    margin-left: -250px;
  }
}

.font-upload-btn {
  .plt-iconbtn {
    min-width: var(--plt-size-400);
    max-width: var(--plt-size-400);
    min-height: var(--plt-size-400);
    max-height: var(--plt-size-400);
    background: transparent;
    &:hover {
      background: transparent;
    }
  }
}

.content-wrapper {
  max-width: 840px;
}

.plt-content-block {
  max-width: 840px;
  // margin-bottom: var(--dss-space-1200);

  // Remove margin from the last section
  &:last-child {
    margin-bottom: var(--dss-space-0);
  } // .plt-content-wrapper {
  //   // .typo-title {
  //   //   font-size: var(--font-size-500);
  //   //   font-weight: var(--font-weight-600);
  //   //   // color: var(--text-primary);
  //   //   line-height: var(--line-height-130);
  //   // }

  //   .typo-des {
  //     line-height: var(--line-height-120);
  //   }
  // }
}
