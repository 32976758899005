.pagination {
  .pagination-list {
    display: inline;
  }
}
.pagination .pagination-list {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: var(--dss-size-800);
    max-width: var(--dss-size-800);
    min-height: var(--dss-size-800);
    max-height: var(--dss-size-800);
  .pagination-list-item {
    border-radius: var(--dss-border-radius-100);
    transition: background-color 0.3s;
  }
  
}
.pagination .pagination-list .pagination-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: var(--dss-size-800);
  max-width: var(--dss-size-800);
  min-height: var(--dss-size-800);
  max-height: var(--dss-size-800);
  color: var(--dss-text-primary);
  border-radius: var(--dss-border-radius-100);
  transition: background-color 0.3s;
  cursor: pointer;
  &.active {
    background-color: var(--dss-background-selected);
    color: #fff;;
  }
  &:hover:not(.active) {
    background-color: var(--dss-background-secondary);
  } 
}
