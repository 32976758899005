.plt-filter-dropdown {
  position: relative;
  .user-dropdown {
    top: 30px;
    right: 8px;
  }
}

.plt-table-team {
  .plt-table-container {
    table {
      border-spacing: 0;
      padding-bottom: 150px;
      tr {
        background: transparent;
      }
      th {
        padding: var(--plt-space-200) 0;
        color: var(--plt-text-secondary);
        font-size: var(--plt-font-size-300);
        font-weight: var(--plt-font-weight-semi-bold);
        line-height: var(--plt-line-height-150);
        background: transparent;
        border-top: 1px solid var(--plt-border-secondary);
      }
      td {
        border-top: 1px solid var(--plt-border-secondary);
        border-radius: 0 !important;
        background: transparent;
        padding: var(--plt-space-400) 0;
        vertical-align: middle;
        &:first-child {
          min-width: initial;
        }
      }
    }
  }
}

.plt-role-description {
  min-width: 100px;
}