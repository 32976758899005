.account-container {
  max-width: 632px;

  .image-dropdown {
    position: relative;
    max-width: 128px;
    .user-dropdown {
      top: 100px;
      left: 0;
    }
    .dropdown-item-icon {
      width: 16px;
      height: 16px;
    }
    .image-dropdown-edit  {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 8px;
      right: 8px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: var(--plt-background-primary);
    }
  }

  .filetype {
    &[type="file"] {
      height: 0;
      overflow: hidden;
      width: 0;
    }
    
    &[type="file"] + label {
      outline: none;
      position: relative;
      transition: all 0.3s;
      vertical-align: middle;
      margin-left: -6px;
      // padding: 0;
      
      // &:hover {
      //   background-color: darken(#f15d22, 10%);
      // }
    }
  }
}

.password-block {
  background-color: var(--plt-background-tertiary);
  border: 1px solid var(--plt-background-primary);
  padding: var(--plt-space-600);
  border-radius: var(--plt-space-200);
  .password-block-txt {
    max-width: 75%;
    
  }
}



