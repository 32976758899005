/* Custom Radio Group Styles */
.dss-custom-radio-group {
  --_dss-radio-gap-horizontal: var(--dss-space-600);
  --_dss-radio-gap-vertical: var(--dss-space-400);
  --_dss-radio-label-padding-left: var(--dss-space-800);
  --_dss-radio-border-width: var(--dss-border-width-100);
  --_dss-radio-border-color: var(--dss-background-tertiary);
  --_dss-radio-border-radius: var(--dss-border-radius-full);
  --_dss-radio-outer-circle-size: var(--dss-size-600);
  --_dss-radio-outer-inner-size: var(--dss-font-size-300);
  --_dss-radio-hover-border-color: var(--dss-background-selected);
  --_dss-radio-checked-border-color: var(--dss-background-selected);
  --_dss-radio-dot-color: var(--dss-background-selected);
  --_dss-radio-border-width-outlined: var(--dss-border-width-500);
  --_dss-radio-text-disabled: var(--dss-background-disabled);
  --_dss-radio-text-font-size: var(--dss-font-size-200);
  --_dss-radio-text-font-weight: var(--dss-font-weight-regular);
  --_dss-radio-text-color: var(--dss-text-primary);
  padding: var(--dss-space-300) var(--dss-space-600);
  
  display: flex;
  // justify-content: center;
  gap: var(--_dss-radio-gap-horizontal);

  &-vertical {
    flex-direction: column;
    gap: var(--_dss-radio-gap-vertical);
  }
  

  .dss-radio-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    .dss-radio[type="radio"] {
      &:disabled,
      &.disabled,
      &[aria-disabled="true"] {
        pointer-events: none;

        &:not(:checked)+.dss-radio-label::after {
          opacity: 1;
          transform: scale(1);
        }

        &+.dss-radio-label {
          transition: all .4s;
          color: var(--_dss-radio-text-disabled);

          &::before {
            --_dss-radio-border-color: var(--dss-background-disabled);
            --_dss-radio-hover-border-color: var(--dss-background-disabled);
          }

          &::after {
            --_dss-radio-dot-color: var(--dss-background-disabled);
          }
        }
      }

      &:checked,
      &:not(:checked) {
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        opacity: 0;
        visibility: hidden;
      }

      &:checked+.dss-radio-label,
      &:not(:checked)+.dss-radio-label {
        position: relative;
        padding-left: var(--_dss-radio-label-padding-left);
        cursor: pointer;
        display: inline-block;
        font-size: var(--_dss-radio-text-font-size);
        font-weight: var(--_dss-radio-text-font-weight);
        color: var(--_dss-radio-text-color);;

        &:hover {
          &::before {
            --_dss-radio-border-color: var(--_dss-radio-hover-border-color);
          }
        }
      }

      &:checked+.dss-radio-label:before,
      &:not(:checked)+.dss-radio-label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: var(--_dss-radio-outer-circle-size);
        height: var(--_dss-radio-outer-circle-size);
        border: var(--_dss-radio-border-width) solid
          var(--_dss-radio-border-color);
        border-radius: var(--_dss-radio-border-radius);
        transition: all 300ms ease;
        transition-property: border;
      }

      &:checked+.dss-radio-label::before {
        border: var(--_dss-radio-border-width) solid
          var(--_dss-radio-checked-border-color);
      }

      &:checked+.dss-radio-label:after,
      &:not(:checked)+.dss-radio-label:after {
        content: "";
        width: var(--_dss-radio-outer-inner-size);
        height: var(--_dss-radio-outer-inner-size);
        background-color: var(--_dss-radio-dot-color);
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        transition: all var(--_dss-radio-transition-duration) ease;
        display: inline-block;
      }

      &:not(:checked)+.dss-radio-label::after {
        opacity: 0;
        transform: scale(0);
      }

      &:checked+.dss-radio-label::after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .dss-radio-label-outlined {
    position: relative;
    padding-left: var(--_dss-radio-label-padding-left);
    cursor: pointer;
    display: inline-block;
    font-size: var(--_dss-radio-text-font-size);
    font-weight: var(--_dss-radio-text-font-weight);
    color: var(--_dss-radio-text-color);

    &::before {
      content: "";
      position: absolute;
      left: 2px;
      top: 0;
      width: var(--_dss-radio-outer-circle-size);
      height: var(--_dss-radio-outer-circle-size);
      border: var(--_dss-radio-border-width) solid
        var(--_dss-radio-border-color);
      border-radius: var(--_dss-radio-border-radius);
      transition: all 300ms ease;
      transition-property: border;
    }

    &:hover::before {
      --_dss-radio-border-color: var(--_dss-radio-hover-border-color);
    }

    &:checked::before {
      border: var(--_dss-radio-border-width-outlined) solid
        var(--_dss-radio-checked-border-color);
    }

    &:after {
      content: "";
      width: var(--_dss-radio-outer-inner-size);
      height: var(--_dss-radio-outer-inner-size);
      background-color: var(--_dss-radio-dot-color);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      transition: all var(--_dss-radio-transition-duration) ease;
      display: inline-block;
    }

    &:not(:checked):after {
      opacity: 0;
      transform: scale(0);
    }

    &:checked:after {
      opacity: 1;
      transform: scale(1);
    }
  }

  .dss-radio-outlined[type="radio"] {
    display: none;

    &:checked + .dss-radio-label-outlined::before {
      border: var(--_dss-radio-border-width-outlined) solid
        var(--_dss-radio-checked-border-color);
    }

    &:disabled,
    &.disabled,
    &[aria-disabled="true"] {
      pointer-events: none;

      &:not(:checked)+.dss-radio-label-outlined::after {
        opacity: 0;
        transform: scale(1);
      }

      &+.dss-radio-label-outlined {
        color: var(--_dss-radio-text-disabled);

        &::before {
          --_dss-radio-hover-border-color: var(--dss-background-disabled);
          border: var(--_dss-radio-border-width-outlined) solid
            var(--dss-background-disabled);
        }

        &::after {
          --_dss-radio-border-color: var(--dss-background-disabled);
        }
      }
    }
  }

  &-xs {
    --_dss-radio-outer-circle-size: var(--dss-size-400);
    --_dss-radio-outer-inner-size: var(--dss-size-200);
  }

  &-md {
    --_dss-radio-outer-circle-size: var(--dss-size-500);
    --_dss-radio-outer-inner-size: var(--dss-size-300);
  }

}


  

