.container {
  display: flex;
  justify-content: center;
  height: 100vh;
  padding: var(--plt-space-600);

  .left {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    padding-right: var(--plt-space-600);

    .logo {
      display: flex;
      align-items: center;
      // width: 50%;

      .logo-link {
        text-decoration: none;
      }

      .logo-icon {
        min-width: 36px;
        max-width: 36px;
        min-height: 36px;
        max-height: 36px;
      }

      h2 {
        color: #000;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: bold;
        line-height: 1.5;
        // margin-left: var(--space-200);
      }
    }

    .children {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  .right {
    width: 50%;
    // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    height: 100%;
    border-radius: 14px;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 14px;
    }

    // @include mq(phone) {
    //   display: none;
    // }
  }
}

@media (max-width: 768px) {
  .left {
    width: 100% !important;
    padding-right: 0 !important;
  }

  .right {
    display: none;
  }
}