// .plt-table-container {
//   width: 100%;
//   max-width: 880px;
//   table {
//     width: 100%;
//     border-collapse: separate;
//     border-spacing: 0 8px;

//    tr,td,th {
//     text-align: left;
//    }

//     th {
//       min-width: 160px;
//       padding: var(--plt-space-100)  var(--plt-space-400);
//       color: var(--plt-text-tertiary);
//       font-size: var(--plt-font-size-300);
//       font-weight: var(--plt-font-weight-regular);
//       line-height: var(--plt-line-height-120);
//     }

//     td {
//       padding: var(--plt-space-400);
//       color: var(--plt-text-secondary);
//       font-size: var(--plt-font-size-200);
//       font-weight: var(--plt-font-weight-regular);
//       line-height: var(--plt-line-height-150);
//       background: var(--plt-background-tertiary);
//       vertical-align: top;
//     }

//     tr td:first-child { border-top-left-radius: var(--plt-border-radius-300); border-bottom-left-radius: var(--plt-border-radius-300); }
//     tr td:last-child { border-top-right-radius: var(--plt-border-radius-300); border-bottom-right-radius: var(--plt-border-radius-300); }

//   }
// }

.dss-scale-table-container {
  overflow: auto;
}

/* adding space to all the cells */
.dss-scale-table th {
  padding: var(--plt-space-200) var(--plt-space-400);
  color: var(--plt-text-tertiary);
  font-size: var(--plt-font-size-300);
  font-weight: var(--plt-font-weight-regular);
  line-height: var(--plt-line-height-120);
}

.dss-scale-table td {
  padding: var(--plt-space-300) var(--plt-space-400);
  font-size: var(--plt-font-size-200);
  font-weight: var(--plt-font-weight-regular);
  color: var(--plt-text-secondary);
  line-height: var(--plt-line-height-120);
}
.dss-scale-table td, .plt-heading-usage, .plt-heading-info {
 font-size: var(--plt-font-size-200);
    font-weight: var(--plt-font-weight-regular);
    color: var(--plt-text-secondary);
    line-height: var(--plt-line-height-120);
  }
/*end of adding space to all the cells */

/* basic table styles */
.dss-scale-table {
  width: 100%;
}
.dss-scale-table,
.dss-scale-table td,
.dss-scale-table tr,
.dss-scale-table th {
  border-collapse: collapse;
  border: 1px solid var(--plt-border-secondary);
  text-align: left;
  vertical-align: top;
}

.dss-scale-table th:last-child {
  min-width: 240px;
}

.dss-scale-table td{
  width: 248px;
}