

.dss-modal-content {
  background: var(--dss-background-surface);
  border-radius: var(--dss-border-radius-300) var(--dss-border-radius-300) 0 0;
  min-width: 472px;
  max-width: 800px; // Adjust max-width as needed
}
.dss-iconbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: var(--dss-size-800);
  max-width: var(--dss-size-800);
  min-height: var(--dss-size-800);
  max-height: var(--dss-size-800);
  background: var(--dss-background-tertiary);
  padding: 0;
  border-radius: var(--dss-border-radius-150);
  transition: all 0.35s;
  cursor: pointer;
}
.dss-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--dss-space-300) var(--dss-space-500);
}

.dss-modal-close-icon {
  background: transparent;
  transition: all 0.4s;
  cursor: pointer;

  &:hover {
    background: var(--dss-background-tertiary);
    border-radius: var(--dss-border-radius-100);
    transition: all 0.35s;
  }
}

.dss-modal-body {
  min-height: 100%;
  max-height: calc(100vh - 210px);
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 472px;
  background: var(--dss-background-surface);
}

.dss-preview {
  display: flex;
  justify-content: center;
  padding: var(--dss-space-600);
  position: sticky;
  top: 0;
  z-index: 2;
}

.dss-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: var(--dss-space-400);
  padding: var(--dss-space-500);
  min-width: 472px;
  background: var(--dss-background-surface);
  border-radius: 0 0 var(--dss-border-radius-300) var(--dss-border-radius-300);
}



