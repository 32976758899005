.dss-toggler {
	// --_toggler-color: var(--dss-text-primary);
	display: flex;
	align-items: center;
	// column-gap: var(--dss-size-16);
	pointer-events: none;

	.dss-toggler-elem {
		position: relative;
		display: inline-flex;
		width: var(--dss-size-800);
		height: 16px;
		transition: all .4s;
		pointer-events: all;
	}

	.dss-toggler-slider {
		position: absolute;
		inset: 0;
		top: 0;
		left: 0;
		// right: 0;
		// bottom: 0;
		background-color: var(--dss-background-default);
		border-radius: var(--dss-border-radius-full);
		transition: all .4s;
		cursor: pointer;
		&:hover {
			background-color: var(--dss-background-default-hover);
		}

		&:before {
			content: '';
			position: absolute;
			top: var(--dss-space-50);
			left: var(--dss-space-50);
			// top: calc(50% - 12px / 2);
			background-color: var(--dss-background-surface);
			width: var(--dss-size-300);
			height: var(--dss-size-300);
			aspect-ratio: 1;
			border-radius: var(--dss-border-radius-full);
			// box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
			box-shadow: var(--dss-shadow-300);
			transition: all .4s;
		}
	}

	// .dss-toggler-content {
	// 	display: flex;
	// 	align-items: center;
	// 	column-gap: 8px;
	// }

	// .dss-toggler-icon {
	// 	font-size: 18px;
	// 	font-weight: 400;
	// 	color: var(--_toggler-color);
	// 	line-height: 1.3;
	// }

	// .dss-toggler-text {
	// 	font-weight: 400;
	// 	font-size: 14px;
	// 	color: var(--_toggler-color);
	// 	line-height: 1.3;
	// }

	.dss-toggler-toggler-input {
		display: none;

		&:checked {
			& + .dss-toggler-slider {
				// --_toggler-active-color: var(--dss-accent);
				// background-color: var(--_toggler-active-color);
				// background-color: var(--dss-background-default);
        background-color: var(--dss-background-selected);

				&:before {
					transform: translateX(16px);
				}
			}
		}
	}

	
	&-disable {

		.dss-toggler-slider {
			background-color: var(--dss-background-disabled);
			&::before {
				box-shadow: var(--dss-shadow-0);
			}
		}
		.dss-toggler-elem {
			pointer-events: none;
		}
		.dss-toggler-toggler-input {
				&:checked {
					& + .dss-toggler-slider {
						background-color: var(--dss-background-disabled);
					}
				}
		}
		}

	// Enable toggle option also for label text
	// &.dss-toggler-cta {
	// 	pointer-events: all;
	// }

	// &.dss-toggler {
	// 	&-primary {
	// 		.dss-toggler-toggler-input {
	// 			&:checked {
	// 				& + .dss-toggler-slider {
	// 					--_toggler-active-color: var(--dss-accent);
	// 				}
	// 			}
	// 		}
	// 	}

		// &-secondary {
		// 	.dss-toggler-toggler-input {
		// 		&:checked {
		// 			& + .dss-toggler-slider {
		// 				--_toggler-active-color: var(--dss-primary);
		// 			}
		// 		}
		// 	}
		// }
	// }
  &-large {
    .dss-toggler-elem {
      width: var(--dss-size-1000);
      height: 20px;
    }
    .dss-toggler-slider {
      &:before {
        // top: calc(50% - 16px / 2);
        width: var(--dss-size-400);
        height: var(--dss-size-400);
      }
			
    }
		.dss-toggler-toggler-input:checked + .dss-toggler-slider:before {
			transform: translateX(20px);
		}
  }
	
	.dss-toggler-text  {
		display: none;
		position: absolute;
		margin-left: 40px;
    width: max-content;
	}
}

.dss-toggler-withlabel  {
	.dss-toggler-text  {
		display: inline-flex;
	}
	&.dss-toggler-large {
		.dss-toggler-text  {
			margin-left: 48px;
		}
	}
}