.guideline-card {
  row-gap: var(--plt-space-800);
  flex-wrap: wrap;
}

.guideline-wrapper {
  min-width: calc((100%/2) - 12px);
  max-width: calc((100%/2) - 12px);
  .guideline-badge {
    color: var(--plt-text-button-fill-primary);
    border-radius: var(--plt-border-radius-150);
    padding: 0 10px;
    max-width: fit-content;
    &.active{
      background: #0A8A00;
    }
    &.inactive{
      background: #CE463D;
    }
  }
}

.guideline-detail-wrapper {
  background: var(--plt-background-primary);
  border: 1px solid var(--plt-border-secondary);
  border-radius: var(--plt-border-radius-300);
  padding: var(--plt-space-400);

}

