/**
 * Do not edit directly
 * Generated on Wed, 04 Sep 2024 07:50:17 GMT
 */

:root {
  --plt-background-button-fill-primary: #630B99;
  --plt-background-button-fill-pressed: #5A0A8B;
  --plt-background-button-fill-hovered: #5A0A8B;
  --plt-background-button-fill-disabled: #17171740;
  --plt-background-button-fill-icon-fill: #ffffff;
  --plt-background-button-fill-icon-fill-disabled: #ffffff;
  --plt-background-button-strock-icon-fill: #630B99;
  --plt-background-button-strock-primary: #630B99;
  --plt-background-button-strock-hovered: #EFE7F5;
  --plt-background-button-strock-pressed: #EFE7F5;
  --plt-background-button-strock-disabled: #17171740;
  --plt-background-button-strock-icon-fill-disabled: #17171740;
  --plt-background-button-danger-primary: #D86B64;
  --plt-background-button-danger-hovered: #CE463D;
  --plt-background-button-danger-pressed: #CE463D;
  --plt-background-button-danger-disabled: #17171740;
  --plt-background-button-danger-icon-fill: #ffffff;
  --plt-background-button-danger-icon-fill-disabled: #ffffff;
  --plt-background-button-default-primary: #EFE7F5;
  --plt-background-button-default-pressed: #CFB3DF;
  --plt-background-button-default-hovered: #CFB3DF;
  --plt-background-button-default-disabled: #1717170d;
  --plt-background-button-default-icon-fill: #171717;
  --plt-background-button-default-icon-fill-disabled: #17171740;
  --plt-background-button-ghost-primary: #ffffff00;
  --plt-background-button-ghost-hovered: #EFE7F5;
  --plt-background-button-ghost-pressed: #EFE7F5;
  --plt-background-button-ghost-disabled: #17171740;
  --plt-background-button-ghost-icon-fill-disabled: #17171740;
  --plt-background-button-ghost-icon-fill: #630B99;
  --plt-background-surface: #ffffff;
  --plt-background-surface-hover: #F6F6F6;
  --plt-background-negative: #CE463D;
  --plt-background-disabled: #F6F6F6;
  --plt-background-caution: #F26A2F;
  --plt-background-positive: #0A8A00;
  --plt-background-informative: #3924C0;
  --plt-background-inverse: #171717;
  --plt-background-primary: #DDDDDD;
  --plt-background-secondary: #F0F0F0;
  --plt-background-tertiary: #F6F6F6;
  --plt-background-tertiary-hover: #EFE7F5;
  --plt-background-neutral: #1717170d;
  --plt-background-selected: #630B99;
  --plt-background-on-bg-hover: #ffffff26;
  --plt-background-on-bg-active: #ffffff;
  --plt-background-on-bg-default: #ffffff;
  --plt-background-surface-transparent: #17171780;
  --plt-background-surface-transparent-active: #171717e6;
  --plt-background-surface-selected: #FF8B64;
  --plt-background-surface-transparent-hover: #171717cc;
  --plt-background-surface-transparent-disabled: #17171726;
  --plt-background-default: #DDDDDD;
  --plt-background-default-hover: #A6A6A6;
  --plt-background-scrollbar: #F0F0F0;
  --plt-text-button-fill-primary: #ffffff;
  --plt-text-button-fill-hover: #823CAD;
  --plt-text-button-strock-primary: #171717;
  --plt-text-button-strock-disabled: #17171740;
  --plt-text-button-default-primary: #171717;
  --plt-text-button-default-disabled: #17171740;
  --plt-text-button-danger-primary: #ffffff;
  --plt-text-button-ghost-primary: #630B99;
  --plt-text-button-ghost-disabled: #17171740;
  --plt-text-button-link-primary: #630B99;
  --plt-text-button-link-link-hover: #5A0A8B;
  --plt-text-button-link-pressed: #5A0A8B;
  --plt-text-button-link-disabled: #17171740;
  --plt-text-button-link-negative: #D86B64;
  --plt-text-primary: #171717;
  --plt-text-disabled: #17171780;
  --plt-text-secondary: #434343;
  --plt-text-tertiary: #A6A6A6;
  --plt-text-placeholder: #DDDDDD;
  --plt-text-negative: #CE463D;
  --plt-text-caution: #F26A2F;
  --plt-text-positive: #0A8A00;
  --plt-text-informative: #3924C0;
  --plt-text-on-bg-fill: #ffffff;
  --plt-text-selected: #630B99;
  --plt-text-text-on-banner: #ffffff;
  --plt-border-primary: #F0F0F0;
  --plt-border-secondary: #DDDDDD;
  --plt-border-tertiary: #A6A6A6;
  --plt-border-negative: #CE463D;
  --plt-border-disabled: #17171708;
  --plt-border-caution: #F26A2F;
  --plt-border-positive: #0A8A00;
  --plt-border-informative: #3924C0;
  --plt-border-on-bg: #17171726;
  --plt-border-transparent: #ffffff26;
  --plt-border-input: #DDDDDD;
  --plt-border-input-active: #A6A6A6;
  --plt-icon-primary: #171717;
  --plt-icon-disabled: #17171780;
  --plt-icon-secondary: #DDDDDD;
  --plt-icon-selected: #5A0A8B;
  --plt-icon-on-bg-fill: #ffffff;
  --plt-icon-emphasis: #B78FD0;
  --plt-icon-negative: #CE463D;
  --plt-primary-100: #EFE7F5;
  --plt-primary-200: #CFB3DF;
  --plt-primary-300: #B78FD0;
  --plt-primary-400: #965CBB;
  --plt-primary-500: #823CAD;
  --plt-primary-600: #630B99;
  --plt-primary-700: #5A0A8B;
  --plt-primary-800: #46086D;
  --plt-primary-900: #360654;
  --plt-primary-1000: #2A0540;
  --plt-supporting-color1-100: #E7F1FC;
  --plt-supporting-color1-200: #B5D5F7;
  --plt-supporting-color1-300: #92C0F3;
  --plt-supporting-color1-400: #60A4ED;
  --plt-supporting-color1-500: #4192E9;
  --plt-supporting-color1-600: #1177E4;
  --plt-supporting-color1-700: #0F6CCF;
  --plt-supporting-color1-800: #0C54A2;
  --plt-supporting-color1-900: #09417D;
  --plt-supporting-color1-1000: #073260;
  --plt-supporting-color2-100: #fffaec;
  --plt-supporting-color2-200: #F3E1B2;
  --plt-supporting-color2-300: #EDD28D;
  --plt-supporting-color2-400: #E5BD5A;
  --plt-supporting-color2-500: #E0B139;
  --plt-supporting-color2-600: #D89D08;
  --plt-supporting-color2-700: #C58F07;
  --plt-supporting-color2-800: #996F06;
  --plt-supporting-color2-900: #775604;
  --plt-supporting-color2-1000: #5B4203;
  --plt-supporting-color3-100: #FFEEF5;
  --plt-supporting-color3-200: #FECADF;
  --plt-supporting-color3-300: #FEB0D0;
  --plt-supporting-color3-400: #FE8CBA;
  --plt-supporting-color3-500: #FD76AD;
  --plt-supporting-color3-600: #FD5498;
  --plt-supporting-color3-700: #E64C8A;
  --plt-supporting-color3-800: #B43C6C;
  --plt-supporting-color3-900: #8B2E54;
  --plt-supporting-color3-1000: #6A2340;
  --plt-secondary-100: #FFE1D7;
  --plt-secondary-200: #FFA689;
  --plt-secondary-300: #FF9A79;
  --plt-secondary-400: #FF8B64;
  --plt-secondary-500: #FF794D;
  --plt-secondary-600: #FF6D3E;
  --plt-secondary-700: #FF622F;
  --plt-secondary-800: #FC4A0F;
  --plt-secondary-900: #ED3B00;
  --plt-secondary-1000: #E03700;
  --plt-neutrals-100: #ffffff;
  --plt-neutrals-200: #F6F6F6;
  --plt-neutrals-300: #F0F0F0;
  --plt-neutrals-400: #DDDDDD;
  --plt-neutrals-500: #A6A6A6;
  --plt-neutrals-600: #585858;
  --plt-neutrals-700: #434343;
  --plt-neutrals-800: #353535;
  --plt-neutrals-900: #1f1f1f;
  --plt-neutrals-1000: #171717;
  --plt-shadow-0: 0px 0px 0px 0px #171717;
  --plt-shadow-100: 0px 1px 0px 0px #17171780;
  --plt-shadow-200: 0px 3px 1px -1px #17171780;
  --plt-shadow-300: 0px 4px 6px -2px #17171780;
  --plt-shadow-400: 0px 8px 16px -4px #17171780;
  --plt-shadow-500: 0px 12px 20px -8px #17171780;
  --plt-shadow-600: 0px 20px 20px -8px #17171780;
  --plt-shadow-fill-pressed-extra-large: 0px 0px 0px 6px #CFB3DF;
  --plt-shadow-fill-pressed-large: 0px 0px 0px 5px #CFB3DF;
  --plt-shadow-fill-pressed-normal: 0px 0px 0px 3px #CFB3DF;
  --plt-shadow-fill-pressed-dense: 0px 0px 0px 3px #CFB3DF;
  --plt-shadow-fill-pressed-denser: 0px 0px 0px 3px #CFB3DF;
  --plt-shadow-default-pressed-extra-large: 0px 0px 0px 6px #F6F6F6;
  --plt-shadow-default-pressed-large: 0px 0px 0px 5px #F6F6F6;
  --plt-shadow-default-pressed-normal: 0px 0px 0px 3px #F6F6F6;
  --plt-shadow-default-pressed-dense: 0px 0px 0px 3px #F6F6F6;
  --plt-shadow-default-pressed-denser: 0px 0px 0px 3px #F6F6F6;
  --plt-shadow-danger-pressed-extra-large: 0px 0px 0px 6px #F0C6C3;
  --plt-shadow-danger-pressed-large: 0px 0px 0px 5px #F0C6C3;
  --plt-shadow-danger-pressed-normal: 0px 0px 0px 3px #F0C6C3;
  --plt-shadow-danger-pressed-dense: 0px 0px 0px 3px #F0C6C3;
  --plt-shadow-danger-pressed-denser: 0px 0px 0px 3px #F0C6C3;
  --plt-shadow-strock-pressed-extra-large: 0px 0px 0px 6px #CFB3DF;
  --plt-shadow-strock-pressed-large: 0px 0px 0px 5px #CFB3DF;
  --plt-shadow-strock-pressed-normal: 0px 0px 0px 3px #CFB3DF;
  --plt-shadow-strock-pressed-dense: 0px 0px 0px 3px #CFB3DF;
  --plt-shadow-strock-pressed-denser: 0px 0px 0px 3px #CFB3DF;
  --plt-shadow-ghost-pressed-extra-large: 0px 0px 0px 6px #CFB3DF;
  --plt-shadow-ghost-pressed-large: 0px 0px 0px 5px #CFB3DF;
  --plt-shadow-ghost-pressed-normal: 0px 0px 0px 3px #CFB3DF;
  --plt-shadow-ghost-pressed-dense: 0px 0px 0px 3px #CFB3DF;
  --plt-shadow-ghost-pressed-denser: 0px 0px 0px 3px #CFB3DF;
  --plt-font-family-primary: Bricolage Grotesque;
  --plt-font-family-secondary: Rethink Sans;
  --plt-line-height-90: 90%;
  --plt-line-height-100: 100%;
  --plt-line-height-120: 120%;
  --plt-line-height-130: 130%;
  --plt-line-height-140: 140%;
  --plt-line-height-150: 150%;
  --plt-font-weight-regular: 400;
  --plt-font-weight-medium: 500;
  --plt-font-weight-semi-bold: 600;
  --plt-font-weight-bold: 700;
  --plt-font-size-100: 12px;
  --plt-font-size-200: 14px;
  --plt-font-size-300: 16px;
  --plt-font-size-400: 19px;
  --plt-font-size-500: 23px;
  --plt-font-size-600: 28px;
  --plt-font-size-700: 34px;
  --plt-font-size-800: 40px;
  --plt-font-size-900: 48px;
  --plt-font-letter-spacing-normal: 0px;
  --plt-font-letter-spacing-dense: -0.2px;
  --plt-font-letter-spacing-denser: -0.3px;
  --plt-font-letter-spacing-densest: -0.54px;
  --plt-text-case-none: none;
  --plt-text-case-uppercase: uppercase;
  --plt-text-case-lowercase: lowerCase;
  --plt-paragraph-spacing-0: 0px;
  --plt-paragraph-spacing-100: 8px;
  --plt-paragraph-spacing-150: 12px;
  --plt-paragraph-spacing-200: 16px;
  --plt-text-decoration-none: none;
  --plt-text-decoration-underline: underline;
  --plt-text-decoration-strike-through: line-through;
  --plt-border-radius-0: 0px;
  --plt-border-radius-50: 2px;
  --plt-border-radius-100: 4px;
  --plt-border-radius-150: 6px;
  --plt-border-radius-200: 8px;
  --plt-border-radius-300: 12px;
  --plt-border-radius-400: 16px;
  --plt-border-radius-500: 20px;
  --plt-border-radius-700: 28px;
  --plt-border-radius-full: 9999px;
  --plt-space-0: 0px;
  --plt-space-25: 1px;
  --plt-space-50: 2px;
  --plt-space-100: 4px;
  --plt-space-150: 6px;
  --plt-space-200: 8px;
  --plt-space-300: 12px;
  --plt-space-400: 16px;
  --plt-space-500: 20px;
  --plt-space-600: 24px;
  --plt-space-800: 32px;
  --plt-space-1000: 40px;
  --plt-space-1200: 48px;
  --plt-space-1600: 64px;
  --plt-space-2000: 80px;
  --plt-space-2400: 96px;
  --plt-space-2800: 112px;
  --plt-space-3200: 128px;
  --plt-space-button-extra-large: 16px 24px;
  --plt-space-button-large: 12px 20px;
  --plt-space-button-normal: 12px 16px;
  --plt-space-button-dense: 8px 16px;
  --plt-space-button-denser: 4px 8px;
  --plt-space-button-group-gap: 12px;
  --plt-ui-text-sm-medium: typography-plt-ui-text-sm-medium-group;
  --plt-ui-text-sm-medium-font-family: Rethink Sans;
  --plt-ui-text-sm-medium-font-weight: 500;
  --plt-ui-text-sm-medium-font-size: 14px;
  --plt-ui-text-sm-medium-line-height: 120%;
  --plt-ui-text-sm-medium-letter-spacing: 0px;
  --plt-ui-text-sm-medium-paragraph-spacing: 0px;
  --plt-ui-text-sm-medium-text-decoration: none;
  --plt-ui-text-sm-semi-bold: typography-plt-ui-text-sm-semi-bold-group;
  --plt-ui-text-sm-semi-bold-font-family: Rethink Sans;
  --plt-ui-text-sm-semi-bold-font-weight: 600;
  --plt-ui-text-sm-semi-bold-font-size: 14px;
  --plt-ui-text-sm-semi-bold-line-height: 120%;
  --plt-ui-text-sm-regular: typography-plt-ui-text-sm-regular-group;
  --plt-ui-text-sm-regular-font-family: Rethink Sans;
  --plt-ui-text-sm-regular-font-weight: 400;
  --plt-ui-text-sm-regular-font-size: 14px;
  --plt-ui-text-sm-regular-line-height: 120%;
  --plt-ui-text-sm-bold: typography-plt-ui-text-sm-bold-group;
  --plt-ui-text-sm-bold-font-family: Rethink Sans;
  --plt-ui-text-sm-bold-font-weight: 700;
  --plt-ui-text-sm-bold-font-size: 14px;
  --plt-ui-text-sm-bold-line-height: 120%;
  --plt-ui-text-xs-medium: typography-plt-ui-text-xs-medium-group;
  --plt-ui-text-xs-medium-font-family: Rethink Sans;
  --plt-ui-text-xs-medium-font-weight: 500;
  --plt-ui-text-xs-medium-font-size: 12px;
  --plt-ui-text-xs-medium-line-height: 120%;
  --plt-ui-text-xs-semi-bold: typography-plt-ui-text-xs-semi-bold-group;
  --plt-ui-text-xs-semi-bold-font-family: Rethink Sans;
  --plt-ui-text-xs-semi-bold-font-weight: 600;
  --plt-ui-text-xs-semi-bold-font-size: 12px;
  --plt-ui-text-xs-semi-bold-line-height: 120%;
  --plt-ui-text-xs-regular: typography-plt-ui-text-xs-regular-group;
  --plt-ui-text-xs-regular-font-family: Rethink Sans;
  --plt-ui-text-xs-regular-font-weight: 400;
  --plt-ui-text-xs-regular-font-size: 12px;
  --plt-ui-text-xs-regular-line-height: 120%;
  --plt-ui-text-md-medium: typography-plt-ui-text-md-medium-group;
  --plt-ui-text-md-medium-font-family: Rethink Sans;
  --plt-ui-text-md-medium-font-weight: 500;
  --plt-ui-text-md-medium-font-size: 16px;
  --plt-ui-text-md-medium-line-height: 120%;
  --plt-ui-text-md-regular: typography-plt-ui-text-md-regular-group;
  --plt-ui-text-md-regular-font-family: Rethink Sans;
  --plt-ui-text-md-regular-font-weight: 400;
  --plt-ui-text-md-regular-font-size: 16px;
  --plt-ui-text-md-regular-line-height: 120%;
  --plt-ui-text-md-bold: typography-plt-ui-text-md-bold-group;
  --plt-ui-text-md-bold-font-family: Rethink Sans;
  --plt-ui-text-md-bold-font-weight: 700;
  --plt-ui-text-md-bold-font-size: 16px;
  --plt-ui-text-md-bold-line-height: 120%;
  --plt-ui-text-lg-medium: typography-plt-ui-text-lg-medium-group;
  --plt-ui-text-lg-medium-font-family: Rethink Sans;
  --plt-ui-text-lg-medium-font-weight: 500;
  --plt-ui-text-lg-medium-font-size: 19px;
  --plt-ui-text-lg-medium-line-height: 120%;
  --plt-body-lg: typography-plt-body-lg-group;
  --plt-body-lg-font-family: Rethink Sans;
  --plt-body-lg-font-weight: 400;
  --plt-body-lg-font-size: 19px;
  --plt-body-lg-line-height: 150%;
  --plt-body-md: typography-plt-body-md-group;
  --plt-body-md-font-family: Rethink Sans;
  --plt-body-md-font-weight: 400;
  --plt-body-md-font-size: 16px;
  --plt-body-md-line-height: 150%;
  --plt-body-sm: typography-plt-body-sm-group;
  --plt-body-sm-font-family: Rethink Sans;
  --plt-body-sm-font-weight: 400;
  --plt-body-sm-font-size: 14px;
  --plt-body-sm-line-height: 150%;
  --plt-body-xs: typography-plt-body-xs-group;
  --plt-body-xs-font-family: Rethink Sans;
  --plt-body-xs-font-weight: 400;
  --plt-body-xs-font-size: 12px;
  --plt-body-xs-line-height: 150%;
  --plt-body-compact-md: typography-plt-body-compact-md-group;
  --plt-body-compact-md-font-family: Rethink Sans;
  --plt-body-compact-md-font-weight: 500;
  --plt-body-compact-md-font-size: 16px;
  --plt-body-compact-md-line-height: 120%;
  --plt-body-compact-sm: typography-plt-body-compact-sm-group;
  --plt-body-compact-sm-font-family: Rethink Sans;
  --plt-body-compact-sm-font-weight: 400;
  --plt-body-compact-sm-font-size: 14px;
  --plt-body-compact-sm-line-height: 120%;
  --plt-body-compact-xs: typography-plt-body-compact-xs-group;
  --plt-body-compact-xs-font-family: Rethink Sans;
  --plt-body-compact-xs-font-weight: 400;
  --plt-body-compact-xs-font-size: 12px;
  --plt-body-compact-xs-line-height: 120%;
  --plt-heading-3xl: typography-plt-heading-3xl-group;
  --plt-heading-3xl-font-family: Bricolage Grotesque;
  --plt-heading-3xl-font-weight: 700;
  --plt-heading-3xl-font-size: 48px;
  --plt-heading-3xl-line-height: 120%;
  --plt-heading-2xl: typography-plt-heading-2xl-group;
  --plt-heading-2xl-font-family: Bricolage Grotesque;
  --plt-heading-2xl-font-weight: 700;
  --plt-heading-2xl-font-size: 40px;
  --plt-heading-2xl-line-height: 120%;
  --plt-heading-xl: typography-plt-heading-xl-group;
  --plt-heading-xl-font-family: Rethink Sans;
  --plt-heading-xl-font-weight: 700;
  --plt-heading-xl-font-size: 34px;
  --plt-heading-xl-line-height: 120%;
  --plt-heading-lg: typography-plt-heading-lg-group;
  --plt-heading-lg-font-family: Rethink Sans;
  --plt-heading-lg-font-weight: 700;
  --plt-heading-lg-font-size: 28px;
  --plt-heading-lg-line-height: 120%;
  --plt-heading-md: typography-plt-heading-md-group;
  --plt-heading-md-font-family: Rethink Sans;
  --plt-heading-md-font-weight: 700;
  --plt-heading-md-font-size: 23px;
  --plt-heading-md-line-height: 120%;
  --plt-heading-sm: typography-plt-heading-sm-group;
  --plt-heading-sm-font-family: Rethink Sans;
  --plt-heading-sm-font-weight: 700;
  --plt-heading-sm-font-size: 19px;
  --plt-heading-sm-line-height: 120%;
  --plt-heading-xs: typography-plt-heading-xs-group;
  --plt-heading-xs-font-family: Rethink Sans;
  --plt-heading-xs-font-weight: 600;
  --plt-heading-xs-font-size: 16px;
  --plt-heading-xs-line-height: 120%;
  --plt-heading-xxs: typography-plt-heading-xxs-group;
  --plt-heading-xxs-font-family: Rethink Sans;
  --plt-heading-xxs-font-weight: 600;
  --plt-heading-xxs-font-size: 14px;
  --plt-heading-xxs-line-height: 120%;
  --plt-border-width-0: 0px;
  --plt-border-width-50: 0.5px;
  --plt-border-width-100: 1px;
  --plt-border-width-150: 1.5px;
  --plt-border-width-200: 2px;
  --plt-border-width-250: 2.5px;
  --plt-border-width-300: 3px;
  --plt-border-width-400: 4px;
  --plt-border-width-500: 5px;
  --plt-size-25: 1px;
  --plt-size-50: 2px;
  --plt-size-100: 4px;
  --plt-size-200: 8px;
  --plt-size-300: 12px;
  --plt-size-400: 16px;
  --plt-size-500: 20px;
  --plt-size-600: 24px;
  --plt-size-800: 32px;
  --plt-size-1000: 40px;
  --plt-size-1200: 48px;
  --plt-size-1400: 56px;
  --plt-alpha-neutrals-100-a: #17171708;
  --plt-alpha-neutrals-200-a: #1717170d;
  --plt-alpha-neutrals-300-a: #17171726;
  --plt-alpha-neutrals-400-a: #17171740;
  --plt-alpha-neutrals-500-a: #17171780;
  --plt-alpha-neutrals-600-a: #171717cc;
  --plt-alpha-neutrals-700-a: #171717e6;
  --plt-alpha-semantic-200-a: #e0075126;
  --plt-alpha-dark-neutral-50-a: #ffffff00;
  --plt-alpha-dark-neutral-100-a: #ffffff80;
  --plt-alpha-dark-neutral-200-a: #ffffff40;
  --plt-alpha-dark-neutral-300-a: #ffffff26;
  --plt-alpha-dark-neutral-400-a: #ffffff0d;
  --plt-alpha-dark-neutral-500-a: #ffffff08;
  --plt-semantics-negative-100: #FAEDEC;
  --plt-semantics-negative-200: #F0C6C3;
  --plt-semantics-negative-300: #E8AAA6;
  --plt-semantics-negative-400: #DE837D;
  --plt-semantics-negative-500: #D86B64;
  --plt-semantics-negative-600: #CE463D;
  --plt-semantics-negative-700: #BB4038;
  --plt-semantics-negative-800: #92322B;
  --plt-semantics-negative-900: #712722;
  --plt-semantics-negative-1000: #571D1A;
  --plt-semantics-caution-100: #FEF0EA;
  --plt-semantics-caution-200: #FBD1BF;
  --plt-semantics-caution-300: #F9BA9F;
  --plt-semantics-caution-400: #F69B74;
  --plt-semantics-caution-500: #F58859;
  --plt-semantics-caution-600: #F26A2F;
  --plt-semantics-caution-700: #DC602B;
  --plt-semantics-caution-800: #AC4B21;
  --plt-semantics-caution-900: #853A1A;
  --plt-semantics-caution-1000: #662D14;
  --plt-semantics-positive-100: #E7F3E6;
  --plt-semantics-positive-200: #B3DBB0;
  --plt-semantics-positive-300: #8EC98A;
  --plt-semantics-positive-400: #5BB154;
  --plt-semantics-positive-500: #3BA133;
  --plt-semantics-positive-600: #0A8A00;
  --plt-semantics-positive-700: #097E00;
  --plt-semantics-positive-800: #076200;
  --plt-semantics-positive-900: #064C00;
  --plt-semantics-positive-1000: #043A00;
  --plt-semantics-informative-100: #EBE9F9;
  --plt-semantics-informative-200: #C2BBEB;
  --plt-semantics-informative-300: #A49AE2;
  --plt-semantics-informative-400: #7A6CD5;
  --plt-semantics-informative-500: #6150CD;
  --plt-semantics-informative-600: #3924C0;
  --plt-semantics-informative-700: #3421AF;
  --plt-semantics-informative-800: #281A88;
  --plt-semantics-informative-900: #1F146A;
  --plt-semantics-informative-1000: #180F51;
  --plt-supporting-color4-100: #FBF0E7;
  --plt-supporting-color4-200: #F2D2B4;
  --plt-supporting-color4-300: #ECBC90;
  --plt-supporting-color4-400: #E39D5E;
  --plt-supporting-color4-500: #DD8A3E;
  --plt-supporting-color4-600: #D56D0E;
  --plt-supporting-color4-700: #C2630D;
  --plt-supporting-color4-800: #974D0A;
  --plt-supporting-color4-900: #753C08;
  --plt-supporting-color4-1000: #592E06;
  --plt-token-set-order-0: core;
  --plt-token-set-order-1: light;
}
