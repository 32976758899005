:root {
  --dss-font-family-primary: "Inter", sans-serif;
  --dss-font-family-secondary: "Inter", sans-serif;
}

.dss-font-family-primary {
  font-family: var(--dss-font-family-primary);
}

.dss-font-family-secondary {
  font-family: var(--dss-font-family-secondary);
}

.dss-heading-3xl {
  // color: var(--dss-text-primary);
  font-size: var(--dss-heading-3xl-font-size);
  font-weight: var(--dss-heading-3xl-font-weight);
  line-height: var(--dss-heading-3xl-line-height);
  // font-family: var(--dss-font-family-secondary);
  font-family: var(--dss-heading-3xl-font-family);
}

.dss-heading-2xl {
  // color: var(--dss-text-primary);
  font-size: var(--dss-heading-2xl-font-size);
  font-weight: var(--dss-heading-2xl-font-weight);
  line-height: var(--dss-heading-2xl-line-height);
  font-family: var(--dss-heading-2xl-font-family);
}

.dss-heading-xl {
  // color: var(--dss-text-primary);
  font-size: var(--dss-heading-xl-font-size);
  font-weight: var(--dss-heading-xl-font-weight);
  line-height: var(--dss-heading-xl-line-height);
  font-family: var(--dss-heading-xl-font-family);
}

.dss-heading-lg {
  // color: var(--dss-text-primary);
  font-size: var(--dss-heading-lg-font-size);
  font-weight: var(--dss-heading-lg-font-weight);
  line-height: var(--dss-heading-lg-line-height);
  font-family: var(--dss-heading-lg-font-family);
}

.dss-heading-md {
  // color: var(--dss-text-primary);
  font-size: var(--dss-heading-md-font-size);
  font-weight: var(--dss-heading-md-font-weight);
  line-height: var(--dss-heading-md-line-height);
  font-family: var(--dss-heading-md-font-family);
}

.dss-heading-sm {
  // color: var(--dss-text-primary);
  font-size: var(--dss-heading-sm-font-size);
  font-weight: var(--dss-heading-sm-font-weight);
  line-height: var(--dss-heading-sm-line-height);
  font-family: var(--dss-heading-sm-font-family);
}

.dss-heading-xs {
  // color: var(--dss-text-primary);
  font-size: var(--dss-heading-xs-font-size);
  font-weight: var(--dss-heading-xs-font-weight);
  line-height: var(--dss-heading-xs-line-height);
  font-family: var(--dss-heading-xs-font-family);
}

.dss-heading-xxs {
  // color: var(--dss-text-primary);
  font-size: var(--dss-heading-xxs-font-size);
  font-weight: var(--dss-heading-xxs-font-weight);
  line-height: var(--dss-heading-xxs-line-height);
  font-family: var(--dss-heading-xxs-font-family);
}

.dss-body-lg {
  // color: var(--dss-text-primary);
  font-size: var(--dss-body-lg-font-size);
  font-weight: var(--dss-body-lg-font-weight);
  line-height: var(--dss-body-lg-line-height);
  font-family: var(--dss-body-lg-font-family);
}

.dss-body-md {
  // color: var(--dss-text-primary);
  font-size: var(--dss-body-md-font-size);
  font-weight: var(--dss-body-md-font-weight);
  line-height: var(--dss-body-md-line-height);
  font-family: var(--dss-body-md-font-family);
}

.dss-body-sm {
  // color: var(--dss-text-primary);
  font-size: var(--dss-body-sm-font-size);
  font-weight: var(--dss-body-sm-font-weight);
  line-height: var(--dss-body-sm-line-height);
  font-family: var(--dss-body-sm-font-family);
}

// .dss-body-xs {
//   color: var(--dss-text-primary);
//   font-size: var(--dss-body-xs-font-size);
//   font-weight: var(--dss-body-xs-font-weight);
//   line-height: var(--dss-body-xs-line-height);
//   font-family: var(--dss-body-xs-font-family);
// }

.dss-body-compact-lg {
  // color: var(--dss-text-primary);
  font-size: var(--dss-body-compact-lg-font-size);
  font-weight: var(--dss-body-compact-lg-font-weight);
  line-height: var(--dss-body-compact-lg-line-height);
  font-family: var(--dss-body-compact-lg-font-family);
}

.dss-body-compact-md {
  // color: var(--dss-text-primary);
  font-size: var(--dss-body-compact-md-font-size);
  font-weight: var(--dss-body-compact-md-font-weight);
  line-height: var(--dss-body-compact-md-line-height);
  font-family: var(--dss-body-compact-md-font-family);
}

.dss-body-compact-sm {
  // color: var(--dss-text-primary);
  font-size: var(--dss-body-compact-sm-font-size);
  font-weight: var(--dss-body-compact-sm-font-weight);
  line-height: var(--dss-body-compact-sm-line-height);
  font-family: var(--dss-body-compact-sm-font-family);
}

.dss-body-compact-xs {
  // color: var(--dss-text-primary);
  font-size: var(--dss-body-compact-xs-font-size);
  font-weight: var(--dss-body-compact-xs-font-weight);
  line-height: var(--dss-body-compact-xs-line-height);
  font-family: var(--dss-body-compact-xs-font-family);
}

.dss-body-compact-xxs {
  // color: var(--dss-text-primary);
  font-size: var(--dss-body-compact-xxs-font-size);
  font-weight: var(--dss-body-compact-xxs-font-weight);
  line-height: var(--dss-body-compact-xxs-line-height);
  font-family: var(--dss-body-compact-xxs-font-family);
}

.dss-body-strong-lg-medium {
  // color: var(--dss-text-primary);
  font-size: var(--dss-body-strong-lg-medium-font-size);
  font-weight: var(--dss-body-strong-lg-medium-font-weight);
  line-height: var(--dss-body-strong-lg-medium-line-height);
  font-family: var(--dss-body-strong-lg-medium-font-family);
}

.dss-body-strong-lg-semi-bold {
  // color: var(--dss-text-primary);
  font-size: var(--dss-body-strong-lg-semi-bold-font-size);
  font-weight: var(--dss-body-strong-lg-semi-bold-font-weight);
  line-height: var(--dss-body-strong-lg-semi-bold-line-height);
  font-family: var(--dss-body-strong-lg-semi-bold-font-family);
}

.dss-body-strong-md-medium {
  // color: var(--dss-text-primary);
  font-size: var(--dss-body-strong-md-medium-font-size);
  font-weight: var(--dss-body-strong-md-medium-font-weight);
  line-height: var(--dss-body-strong-md-medium-line-height);
  font-family: var(--dss-body-strong-md-medium-font-family);
}

.dss-body-strong-md-semi-bold {
  // color: var(--dss-text-primary);
  font-size: var(--dss-body-strong-md-semi-bold-font-size);
  font-weight: var(--dss-body-strong-md-semi-bold-font-weight);
  line-height: var(--dss-body-strong-md-semi-bold-line-height);
  font-family: var(--dss-body-strong-md-semi-bold-font-family);
}

.dss-body-strong-sm-medium {
  // color: var(--dss-text-primary);
  font-size: var(--dss-body-strong-sm-medium-font-size);
  font-weight: var(--dss-body-strong-sm-medium-font-weight);
  line-height: var(--dss-body-strong-sm-medium-line-height);
  font-family: var(--dss-body-strong-sm-medium-font-family);
}

.dss-body-strong-sm-semi-bold {
  // color: var(--dss-text-primary);
  font-size: var(--dss-body-strong-sm-semi-bold-font-size);
  font-weight: var(--dss-body-strong-sm-semi-bold-font-weight);
  line-height: var(--dss-body-strong-sm-semi-bold-line-height);
  font-family: var(--dss-body-strong-sm-semi-bold-font-family);
}

.dss-ui-text-lg {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-lg-font-size-500);
  font-weight: var(--dss-ui-text-lg-font-weight-regular);
  line-height: var(--dss-ui-text-lg-line-height-120);
  font-family: var(--dss-ui-text-lg-font-family);
}

.dss-ui-text-lg-medium {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-lg-medium-font-size);
  font-weight: var(--dss-ui-text-lg-medium-font-weight);
  line-height: var(--dss-ui-text-lg-medium-line-height);
  font-family: var(--dss-ui-text-lg-medium-font-family);
}

.dss-ui-text-lg-semi-bold {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-lg-semi-bold-font-size);
  font-weight: var(--dss-ui-text-lg-semi-bold-font-weight);
  line-height: var(--dss-ui-text-lg-semi-bold-line-height);
  font-family: var(--dss-ui-text-lg-semi-bold-font-family);
}

.dss-ui-text-md {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-md-font-size-400);
  font-weight: var(--dss-ui-text-md-font-weight-regular);
  line-height: var(--dss-ui-text-md-line-height-120);
  font-family: var(--dss-ui-text-md-font-family);
}

.dss-ui-text-md-medium {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-md-medium-font-size);
  font-weight: var(--dss-ui-text-md-medium-font-weight);
  line-height: var(--dss-ui-text-md-medium-line-height);
  font-family: var(--dss-ui-text-md-medium-font-family);
}

.dss-ui-text-md-semi-bold {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-md-semi-bold-font-size);
  font-weight: var(--dss-ui-text-md-semi-bold-font-weight);
  line-height: var(--dss-ui-text-md-semi-bold-line-height);
  font-family: var(--dss-ui-text-md-semi-bold-font-family);
}

.dss-ui-text-sm {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-sm-font-size-300);
  font-weight: var(--dss-ui-text-sm-font-weight-regular);
  line-height: var(--dss-ui-text-sm-line-height-120);
  font-family: var(--dss-ui-text-sm-font-family);
}

.dss-ui-text-sm-medium {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-sm-medium-font-size);
  font-weight: var(--dss-ui-text-sm-medium-font-weight);
  line-height: var(--dss-ui-text-sm-medium-line-height);
  font-family: var(--dss-ui-text-sm-medium-font-family);
}

.dss-ui-text-sm-semi-bold {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-sm-semi-bold-font-size);
  font-weight: var(--dss-ui-text-sm-semi-bold-font-weight);
  line-height: var(--dss-ui-text-sm-semi-bold-line-height);
  font-family: var(--dss-ui-text-sm-semi-bold-font-family);
}

.dss-ui-text-xs {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-xs-font-size-200);
  font-weight: var(--dss-ui-text-xs-font-weight-regular);
  line-height: var(--dss-ui-text-xs-line-height-120);
  font-family: var(--dss-ui-text-xs-font-family);
}

.dss-ui-text-xs-medium {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-xs-medium-font-size);
  font-weight: var(--dss-ui-text-xs-medium-font-weight);
  line-height: var(--dss-ui-text-xs-medium-line-height);
  font-family: var(--dss-ui-text-xs-medium-font-family);
}

.dss-ui-text-xs-semi-bold {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-xs-semi-bold-font-size);
  font-weight: var(--dss-ui-text-xs-semi-bold-font-weight);
  line-height: var(--dss-ui-text-xs-semi-bold-line-height);
  font-family: var(--dss-ui-text-xs-semi-bold-font-family);
}

.dss-ui-text-xxs {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-xxs-font-size-100);
  font-weight: var(--dss-ui-text-xxs-font-weight-regular);
  line-height: var(--dss-ui-text-xxs-line-height-120);
  font-family: var(--dss-ui-text-xxs-font-family);
}

.dss-ui-text-xxs-medium {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-xxs-medium-font-size);
  font-weight: var(--dss-ui-text-xxs-medium-font-weight);
  line-height: var(--dss-ui-text-xxs-medium-line-height);
  font-family: var(--dss-ui-text-xxs-medium-font-family);
}

.dss-ui-text-xxs-semi-bold {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-xxs-semi-bold-font-size);
  font-weight: var(--dss-ui-text-xxs-semi-bold-font-weight);
  line-height: var(--dss-ui-text-xxs-semi-bold-line-height);
  font-family: var(--dss-ui-text-xxs-semi-bold-font-family);
}

//dss button typography

.dss-ui-text-button-text-lg {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-button-large-semibold-font-size);
  font-weight: var(--dss-ui-text-button-large-semibold-font-weight);
  line-height: var(--dss-ui-text-button-large-semibold-line-height);
  font-family: var(--dss-ui-text-button-large-semibold-font-family);
}

.dss-ui-text-button-text-normal {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-button-normal-semibold-font-size);
  font-weight: var(--dss-ui-text-button-normal-semibold-font-weight);
  line-height: var(--dss-ui-text-button-normal-semibold-line-height);
  font-family: var(--dss-ui-text-button-normal-semibold-font-family);
}

.dss-ui-text-button-text-dense {
  // color: var(--dss-text-primary);
  font-size: var(--dss-ui-text-button-dense-semibold-font-size);
  font-weight: var(--dss-ui-text-button-dense-semibold-font-weight);
  line-height: var(--dss-ui-text-button-dense-semibold-line-height);
  font-family: var(--dss-ui-text-button-dense-semibold-font-family);
}

// .dss-ui-text-form-value-text-normal-regular {
//   font-size: var(--dss-ui-text-form-value-text-normal-regular-font-size);
//   font-weight: var(--dss-ui-text-form-value-text-normal-regular-font-weight);
//   line-height: var(--dss-ui-text-form-value-text-normal-regular-line-height);
//   font-family: var(--dss-ui-text-form-value-text-dense-regular-font-family);
// }

// colors
.dss-font-color-primary {
  color: var(--dss-text-primary) !important;
}

.dss-font-color-secondary {
  color: var(--dss-text-secondary) !important;
}

.dss-font-color-tertiary {
  color: var(--dss-text-tertiary) !important;
}
