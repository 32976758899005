.d-block {
  display: block;
}

.d-none {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
.right-2 {
  right: 2;
}

.z-10 {
  z-index: 10;
}
