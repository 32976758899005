.plt-btn-detail-wrapper {
  padding: 36px;
  border-radius:  var(--plt-border-radius-300);
  background: var(--plt-background-tertiary);
}

.plt-btn-box-wrapper {
  flex-wrap: wrap;
  row-gap: 32px;
  .plt-btn-box {
    min-width: calc((100%/2) - 8px);
    max-width: calc((100%/2) - 8px);
  }
}

.plt-detail-list {
  list-style: none;
  li {
    position: relative;
    padding-left: 15px;
    &::before {
      content: "";
      position: absolute;
      top: 10px;
      left: 0;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background: var(--plt-text-secondary);
    }
  }
}