.custom-dropdown {
  position: relative; // Change to relative
  min-width: 239px;

  .dropdown-header {
    display: flex;
    justify-content: space-between;
    transition: all 0.4s;
    cursor: pointer;
    padding: var(--plt-space-300);
    border: var(--plt-border-width-100) solid var(--plt-border-primary);
    border-radius: var(--plt-border-radius-100);
    background-color: var(--plt-background-surface);
    color: var(--plt-text-primary);
    font-size: var(--plt-ui-text-sm-medium-font-size);
    font-weight: var(--plt-ui-text-sm-medium-font-weight);
    line-height: var(--plt-ui-text-sm-medium-line-height);
    font-family: var(--plt-ui-text-sm-medium-font-family);

    &:hover {
      border: var(--plt-border-width-100) solid var(--plt-border-tertiary);
    }

    .plt-custom-select-icon {
      min-width: 16px;
      max-width: 16px;
      min-height: 16px;
      max-height: 16px;
      margin-left: var(--plt-space-200);
    }
  }

  .dropdown-list {
    position: absolute;
    top: 105%;
    left: 0;
    right: 0;
    border: 1px solid var(--plt-border-secondary);
    border-radius: var(--plt-border-radius-100);
    background-color: var(--plt-background-surface);
    padding: var(--plt-space-200);
    z-index: 1000;
    min-width: max-content;
    max-height: 250px;
    overflow-y: auto;

    .dropdown-item {
      padding: var(--plt-space-200);
      cursor: pointer;
      transition: all 0.4s;

      &:hover {
        background-color: var(--plt-background-secondary);
      }
    }
  }

  &.modal-dropdown {
    .dropdown-list {
      position: fixed; // Change to fixed
    }
  }
}