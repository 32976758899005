.plt-contents-container {
  padding: var(--plt-space-1200);
  // Basic text styles
  // h1,
  // h2,
  // h3,
  // h4,
  // h5,
  // h6 {
  //   font-weight: bold;
  //   margin-top: 0.5em;
  //   margin-bottom: 0.5em;
  // }

  // p {
  //   margin-top: 0;
  //   margin-bottom: 1em;
  // }

  // strong,
  // b {
  //   font-weight: bold;
  // }

  // em,
  // i {
  //   font-style: italic;
  // }

  // Lists
  // ul,
  // ol {
  //   padding-left: 20px;
  //   margin-top: 0;
  //   margin-bottom: 1em;

  //   li {
  //     margin-bottom: 0.25em;
  //   }
  // }

  // Links
  a {
    color: #007bff;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .rdw-editor-wrapper {
    border: 1px solid gray;
    border-radius: 4px;

    h6 {
      color: #171717;

      font-family: "Rethink Sans";
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }
  }

  // Blockquote styling
  blockquote {
    padding: 0.5em 10px;
    margin: 0 0 10px;
    border-left: 5px solid #eee;
    background-color: #f9f9f9;
    quotes: "\\201C" "\\201D" "\\2018" "\\2019";

    p {
      &:before {
        content: open-quote;
      }

      &:after {
        content: close-quote;
      }
    }
  }

  // Additional styles for other elements
  code {
    background-color: #f4f4f4;
    padding: 2px 4px;
    border-radius: var(--plt-border-radius-100);
    font-family: monospace;
  }

  pre {
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: var(--plt-border-radius-100);
    overflow-x: auto;

    code {
      background-color: transparent;
      padding: 0;
    }
  }

  // Tables
  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      text-align: left;
      padding: 8px;
      border: 1px solid #ddd;
    }

    th {
      background-color: #f2f2f2;
    }
  }

  // Ensure images are not too large
  img {
    max-width: 100%;
    height: auto;
  }

  // padding-left: 40px;

  .plt-content-wrapper {
    max-width: 784px;

    &-sm {
      max-width: 737px;
    }
  }

  .content-display {
    strong {
      font-weight: bold !important;
    }
  }

  .content-display {
    h1 {
      // font-size: 2em;
      // font-weight: bold;
      // margin: 1em 0;
      font-size: var(--plt-heading-md-font-size);
      color: var(--plt-text-primary);
      font-weight: var(--plt-heading-md-font-weight);
      line-height: var(--plt-heading-md-line-height);
      font-family: var(--plt-heading-md-font-family);
    }

    p {
      color: var(--plt-text-secondary);
      font-size: var(--plt-body-md-font-size);
      font-weight: var(--plt-body-md-font-weight);
      line-height: var(--plt-body-md-line-height);
      font-family: var(--plt-body-md-font-family);
    }

    h2 {
      font-size: 1.75em;
      font-weight: bold;
      // margin: 1em 0;
    }

    h3 {
      font-size: 1.5em;
      font-weight: bold;
      // margin: 1em 0;
    }

    h4 {
      font-size: 1.25em;
      font-weight: bold;
      // margin: 1em 0;
    }

    h5 {
      font-size: 1em;
      font-weight: bold;
      // margin: 1em 0;
    }

    h6 {
      font-size: 0.75em;
      font-weight: bold;
      // margin: 1em 0;
    }

    .superFancyBlockquote {
      border-left: 5px solid #ccc;
      margin: 1.5em 10px;
      padding: 0.5em 10px;
      color: #666;
      font-style: italic;
    }

    .leftAlign {
      text-align: left;
    }

    .rightAlign {
      text-align: right;
    }

    .centerAlign {
      text-align: center;
    }

    .justifyAlign {
      text-align: justify;
    }
  }
}

.overflow-unset {
  overflow: unset !important;
}