.plt-input {
  --_input-wrapper-gap: var(--plt-space-100);
  --_input-label-color: var(--plt-text-form-secondary);
  --_input-label-font-weight: var(--plt-ui-text-form-label-normal-medium-font-weight);
  --_input-label-font-size: var(--plt-ui-text-form-label-normal-medium-font-size);
  --_input-label-line-height: var(--plt-ui-text-form-label-normal-medium-line-height);
  --_input-paddingX: var(--plt-space-300);
  --_input-paddingY: var(--plt-space-300);
  --_input-bg-color: var(--plt-background-form-fill-inactive);
  --_input-border-width: var(--plt-border-width-100);
  --_input-border-color: var(--plt-border-secondary);
  --_input-active-border-color: var(--plt-border-form-filled);
  --_input-border-radius: 8px;
  --_input-placeholder-color: var(--plt-text-form-placeholder);
  --_input-placeholder-font-weight: var(--plt-ui-text-form-value-text-normal-regular-font-weight);
  --_input-placeholder-font-size: var(--plt-ui-text-form-value-text-normal-regular-font-size);
  --_input-text-color: var(--plt-text-primary);
  --_input-caret-color: var(--plt-background-inverse);
  --_input-cursor: text;
  --_input-container-gap: var(--plt-space-200);
  --_input-prefix-color: var(--plt-text-form-tertiary);
  --_input-prefix-font-weight: var(--plt-ui-text-form-value-text-normal-regular-font-weight);
  --_input-prefix-font-size: var(--plt-ui-text-form-value-text-normal-regular-font-size);
  --_input-prefix-line-height: var(--plt-ui-text-form-value-text-normal-regular-line-height);
  --_input-validation-content-gap: var(--plt-space-100);
  --_input-validation-color: var(--plt-text-form-negative);
  --_input-validation-font-size: var(--plt-ui-text-form-validation-normal-regular-font-size);
  --_input-validation-line-height: var(--plt-ui-text-form-validation-normal-regular-line-height);


  &-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 392px;
    gap: var(--_input-wrapper-gap);
  }

  &-label {
    color: var(--_input-label-color);
    text-transform: capitalize;
    font-weight: var(--_input-label-font-weight);
    font-size: var(--_input-label-font-size);
    line-height: var(--_input-label-line-height);
  }

  &-icon {
    min-width: var(--plt-space-400);
    max-width: var(--plt-space-400);
    min-height: var(--plt-space-400);
    max-height: var(--plt-space-400);
  }

  &-container {
    display: flex;
    align-items: center;
    gap: var(--_input-container-gap);
    padding: var(--_input-paddingY) var(--_input-paddingX);
    background-color: var(--_input-bg-color);
    border: var(--_input-border-width) solid var(--_input-border-color);
    border-radius: var(--_input-border-radius);
    min-height: 50px;
    cursor: var(--_input-cursor);

    .plt-input-field {
      flex: 1;
      border: none !important;
      background-color: var(--_input-bg-color);
      cursor: var(--_input-cursor);
      caret-color: var(--_input-caret-color);
      outline: none;
      color: var(--_input-text-color);
      font-weight: var(--_input-placeholder-font-weight);
      font-size: var(--_input-placeholder-font-size);
      resize: none;
      transition: all .35s;

      &::placeholder {
        color: var(--_input-placeholder-color);
        font-weight: var(--_input-placeholder-font-weight);
        font-size: var(--_input-placeholder-font-size);
      }
    }

    &:hover {
      --_input-border-color: var(--plt-border-form-focused);
    }

    &.focused {
      --_input-border-color: var(--plt-border-form-focused);
      --_input-bg-color: var(--plt-background-form-fill-focused);
    }

    &.has-value {
      --_input-border-color: var(--plt-border-form-filled);
      --_input-bg-color: var(--plt-background-form-fill-surface);
    }

    &.error {
      --_input-border-color: var(--plt-border-form-negative);
    }
  }

  &.disabled {
    --_input-label-color: var(--plt-text-form-disabled);
    --_input-border-color: var(--plt-border-form-disabled);
    --_input-active-border-color: var(--plt-border-form-disabled);
    --_input-bg-color: var(--plt-background-form-fill-disabled);
    --_input-prefix-color: var(--plt-text-form-disabled);
    --_input-cursor: no-drop;
    pointer-events: none;

    .plt-input-icon svg *{
      stroke: var(--plt-text-form-disabled);;
    }
  }

  &.read-only {
    --_input-bg-color: var(--plt-background-form-fill-readonly);
  }

  .plt-prefix-text {
    color: var(--_input-prefix-color);
    font-weight: var(--_input-prefix-font-weight);
    font-size: var(--_input-prefix-font-size);
    line-height: var(--_input-prefix-line-height);
  }

  .plt-suffix-text {
    color: var(--_input-prefix-color);
    font-weight: var(--_input-prefix-font-weight);
    font-size: var(--_input-prefix-font-size);
    line-height: var(--_input-prefix-line-height);
  }

  .plt-error-container {
    display: flex;
    align-items: center;
    gap: var(--_input-validation-content-gap);
    transform: translateY(-6px);
    opacity: 0;
    visibility: hidden;
    transition: all .4s;
    z-index: -1;

    &.active {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }

  .plt-error-container {
    .plt-error-message {
      color: var(--_input-validation-color);
      font-size: var(--_input-validation-font-size);
      line-height: var(--_input-validation-line-height);
    }
  }

  .has-value {
    --_input-border-color: var(--plt-border-form-filled);
  }

  
  &-fluid {
    min-width: 100%;
    .plt-input-container {
      position: relative;
      padding: 0 var(--_input-paddingX);
      --floating-label-x: var(--_input-paddingX);
      --floating-label-y: var(--_input-paddingY);
      --floating-label-transform: translateY(calc(10px - var(--floating-label-y)));
      --floating-label-active-transform: scale(1) translateY(-11px) translateX(0px);

      .plt-input-label {
        position: absolute;
        left: var(--floating-label-x);
        font-weight: 400;
        padding-top: 6px;
        padding-bottom: 3px;
        transform: var(--floating-label-transform);
        transform-origin: 0 0;
        transition: all .4s;
        z-index: 1;
      }

      &.focused {
        .plt-input-label {
          --floating-label-transform: var(--floating-label-active-transform)
        }
      }

      &.has-value {
        .plt-input-label {
          --floating-label-transform: var(--floating-label-active-transform)
        }
      }
      
      .plt-input-field {
        padding: 24px 0 6px 0;
      }

      &.leadingicon {
        .plt-input-label {
          --floating-label-x: 36px;
        }
      }

      &.prefix {
        .plt-prefix-text {
          display: none;
        }
      }

      &.focused.prefix, &.has-value.prefix {
        .plt-prefix-text {
          display: block;
          margin-top: 18px;
        }
      }

      &.withtag {
        .plt-input-label {
          --floating-label-transform: var(--floating-label-active-transform)
        }
        .plt-prefix-text {
          display: block;
          margin-top: 18px;
        }
        .plt-tag {
          margin-top: 18px;
        }
      }
    

    }
  }

// text-field dense

  &-sm {
    min-width: 327px;
  --_input-label-font-weight: var(--plt-ui-text-form-label-dense-medium-font-weight);
  --_input-label-font-size: var(--plt-ui-text-form-label-dense-medium-font-size);
  --_input-label-line-height: var(--plt-ui-text-form-label-dense-medium-line-height);
  --_input-paddingX: var(--plt-space-300);
  --_input-paddingY: var(--plt-space-250);
  --_input-placeholder-font-weight: var(--plt-ui-text-form-value-text-dense-regular-font-weight);
  --_input-placeholder-font-size: var(--plt-ui-text-form-value-text-dense-regular-font-size);
  --_input-prefix-font-weight: var(--plt-ui-text-form-value-text-dense-regular-font-weight);
  --_input-prefix-font-size: var(--plt-ui-text-form-value-text-dense-regular-font-size);
  --_input-prefix-line-height: var(--plt-ui-text-form-value-text-dense-regular-line-height);
  --_input-validation-font-size: var(--plt-ui-text-form-validation-dense-regular-font-size);
  --_input-validation-line-height: var(--plt-ui-text-form-validation-dense-regular-line-height);
    .plt-input-container {
      min-height: 36px;
    }
    // text-field fluid - dense
    &.plt-input-fluid {
      min-width: 100%;
      .plt-input-container {
        min-height: 44px;
        .plt-input-label {
          padding-top: 4px;
        }
        .plt-input-field {
          padding: 24px 0 4px 0;
        }
      }
    }
    
  }
}






