.dss-alert {
  --_alert-paddingX: var(--dss-space-300);
  --_alert-paddingY: var(--dss-space-400);
  --_alert-border-radius: var(--dss-border-radius-200);
  --_alert--bg-color: var(--dss-background-alert-fill-informative);
  --_alert-msg-container-gap: var(--dss-space-300);
  --_alert-content-gap: var(--dss-space-200);
  --_alert-msg-color: var(--dss-text-alert-informative);
  --_alert--close-size: var(--dss-space-800);
  --_alert-action-btn-bg: transparent;

  display: flex;
  gap: var(--_alert-content-gap);
  min-width: 336px;
  padding: var(--_alert-paddingY) var(--_alert-paddingX);
  border-radius: var(--_alert-border-radius);


  &-informative {
    background-color: var(--_alert--bg-color);
    --_alert-msg-color: var(--dss-text-alert-informative);

  }

  &-caution {
    --_alert-msg-color: var(--dss-text-alert-caution);

    background-color: var(--dss-background-alert-fill-caution);
  }

  &-success {
    --_alert-msg-color: var(--dss-text-alert-success);
    background-color: var(--dss-background-alert-fill-success);
  }

  &-danger {
    --_alert-msg-color: var(--dss-text-alert-danger);

    background-color: var(--dss-background-alert-fill-danger);
  }

  &-icon {
    min-width: var(--dss-size-500);
    max-width: var(--dss-size-500);
    min-height: var(--dss-size-500);
    max-height: var(--dss-size-500);
  }

  &-msg {
    color: var(--_alert-msg-color);
  }

  &-msg-container {
    display: flex;
    justify-content: space-between;
    gap: var(--_alert-msg-container-gap);
    width: 100%;
  }

  &-btn-group {
    align-items: baseline;
    gap: var(--_alert-content-gap);
  }

  &-close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: var(--_alert--close-size);
    max-width: var(--_alert--close-size);
    min-height: var(--_alert--close-size);
    max-height: var(--_alert--close-size);
    margin-left: auto;
    cursor: pointer;

    svg {
      min-width: 12px;
      max-width: 12px;
      min-height: 12px;
      max-height: 12px;
    }
  }

  &-action-footer {
    flex-direction: column;
    --_alert-msg-container-gap: var(--dss-space-100);
  }
}