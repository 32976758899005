.component-card {
  border: 1px solid var(--plt-border-secondary);
  border-radius: var(--plt-border-radius-300);
  // overflow: hidden;
  .component-details-wrapper {
    overflow: auto;
  }
}

.button-wrapper {
  position: relative;
  padding: var(--plt-space-800) var(--plt-space-600);
  border-radius: var(--plt-border-radius-300) var(--plt-border-radius-300) 0 0;
  transition: all 0.3s;
  .plt-modal-overlay {
    overflow: auto;
  }
}

.component-edit-btn {
  display: none;
  position: absolute;
  right: var(--plt-space-600);
  transition: all 0.3s;
}

.button-wrapper:hover .component-edit-btn {
  display: block;
}

.component-details {
  position: relative;
  background: var(--plt-background-tertiary);
  padding: 28px var(--plt-space-800);
  border-radius: 0 0 var(--plt-border-radius-300) var(--plt-border-radius-300);
  transition: all 0.3s;
  pre {
    max-width: 370px;
  }
}

.component-copy-btn {
  display: none;
  position: absolute;
  top: var(--plt-space-600);
  right: var(--plt-space-600);
  transition: all 0.3s;
}

.component-details:hover .component-copy-btn {
  display: block;
}
