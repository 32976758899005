.plt-component-btn-card {
  border: 1px solid var(--plt-border-secondary);
  border-radius: var(--plt-border-radius-300);
}

.plt-component-btn-wrapper  {
  padding: var(--plt-space-800);
  border-radius: var(--plt-border-radius-300);
}
 



