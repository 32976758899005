.dss-custom-checkbox {
  --_dss-checkbox-gap-horizontal: var(--plt-space-600);
  --_dss-checkbox-border-color: var(--dss-background-selected);
  --_dss-checkbox-check-color: var(--dss-background-selected);
  --_dss-checkbox-border-radius: var(--dss-border-radius-100);
  --_dss-checkbox-border-width: var(--dss-border-width-100);
  --_dss-checkbox-border-padding: var(--dss-size-500);
  --_dss-checkbox-text-color: var(--dss-text-primary);
  --_dss-checkbox-width: var(--dss-size-500);
  --_dss-checkbox-height: var(--dss-size-500);
  --dss-checkbox-checked-width: 5.5px;
  --dss-checkbox-checked-height: 8.5px;
  --_dss-checkbox-checked-top: 3.5px;
  --_dss-checkbox-checked-left: 6px;
  --_dss-checkbox-label-margin-right: 8px;

  display: flex;
  gap: var(--_dss-checkbox-gap-horizontal);

  .dss-checkbox {
    input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }

    label {
      position: relative;
      cursor: pointer;
      color: var(--_dss-checkbox-text-color);

      &:before {
        content: '';
        top: -1px;
        background-color: var(--dss-checkbox-background-color);
        border: var(--_dss-checkbox-border-width) solid
          var(--_dss-checkbox-border-color);
        border-radius: var(--_dss-checkbox-border-radius);
        width: var(--_dss-checkbox-width);
        height: var(--_dss-checkbox-height);
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: var(--_dss-checkbox-label-margin-right);
      }
    }

    input:checked + label:after {
      content: '';
      display: block;
      position: absolute;
      top: var(--_dss-checkbox-checked-top);
      left: var(--_dss-checkbox-checked-left);
      width: var(--dss-checkbox-checked-width);
      height: var(--dss-checkbox-checked-height);
      border: var(--_dss-checkbox-border-width) solid
        var(--_dss-checkbox-check-color);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }


}
.dss-checkbox-wrapper{
  --_checkbox-border-width:var(--dss-border-width-200);
  --_checkbox-border-color:var(--dss-border-input);
  --_checkbox-border-color-hovered:var(--dss-border-selected);
  --_checkbox-border-color-active:var(--dss-border-selected);
  --_checkbox-bg-color:var(--dss-background-surface);
  --_checkbox-bg-color-active:var(--dss-border-selected);
  --_checkbox-border-color-disabled:var(--dss-border-disabled);
  --_checkbox-icon-color:var(--dss-background-surface);
  --_checkbox-icon-size:var(--dss-size-300);
  --_checkbox-size:var(--dss-space-500);
  --_checkbox-border-radius:var(--dss-border-radius-100);
  --_checkbox-label-gap:8px;
  --_checkbox-label-font-color:var(--dss-text-primary);;
  --_checkbox-label-font-spacing:0px;

    display: flex;
  align-items: center;
  gap: var(--_checkbox-label-gap);
  cursor: pointer;
  .dss-checkbox{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: var(--_checkbox-size);
    height: var(--_checkbox-size);
    border-radius: var(--_checkbox-border-radius);
    border: var(--_checkbox-border-width) solid var(--_checkbox-border-color);
    outline: none;
    background-color: var(--_checkbox-bg-color);
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;
    &::after {
      content: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.75L3.83413 6.5L9.5 1' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
    }
    &:checked{
      --_checkbox-border-color:var(--_checkbox-border-color-active);
      background-color: var(--_checkbox-bg-color-active);
      &::after{
        display: flex;
        align-items: center;
        justify-content: center;
      }
  }
  &-label{
    cursor: pointer;
    color: var(--_checkbox-label-font-color);

    letter-spacing: var(--_checkbox-label-font-spacing);
    // &-large{
    //   --_checkbox-label-font-size:var(--dss-font-size-400);
    // }
    // &-small{
    //   --_checkbox-label-font-size:var(--dss-font-size-200);
    // }
    &-disable {
      color: var(--dss-text-disabled);
    }
      }
  &:disabled{
    --_checkbox-border-color-hovered:var(--dss-background-disabled);
    --_checkbox-border-color:var(--dss-background-disabled);
    --_checkbox-bg-color-active:var(--dss-background-surface);
    --_checkbox-icon-color:var(--dss-background-disabled)
  }
  &:hover{
    --_checkbox-border-color:var(--_checkbox-border-color-hovered);
  }
  &-large{
    --_checkbox-size:var(--dss-space-600);
    --_checkbox-icon-size:var(--dss-size-400);
  }
  &-small{
    --_checkbox-size:var(--dss-space-400);
    --_checkbox-icon-size:var(--dss-size-200);
  }
  &-default{
    --_checkbox-border-color:var(--dss-border-tertiary);
    --_checkbox-border-color-hovered:var(--dss-border-selected);
    --_checkbox-border-color-active:var(--dss-border-selected);
    --_checkbox-bg-color-active:var(--dss-background-selected);
    --_checkbox-icon-color:var(--dss-background-surface);
  }
  &-outline{
    --_checkbox-border-color:var(--dss-border-tertiary);
    --_checkbox-border-color-hovered:var(--dss-border-selected);
    --_checkbox-border-color-active:var(--dss-border-selected);
    --_checkbox-bg-color-active:var(--dss-background-surface);
    --_checkbox-icon-color:var(--dss-background-selected);
    &:checked::after {
      // content: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 5.75L4.83413 8.5L10.5 3' stroke='%230F5FCC' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      content: '';
      display: flex;
      position: static;
      margin: 2px auto;
      width: 5px;
      height: 10px;  
      border: solid var(--_checkbox-border-color-active);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    &.dss-checkbox-small::after {
      height: 8px;
      margin: 1px auto;
    }
  }
  &-disable {
    --_checkbox-border-color:var(--dss-border-disabled);
    --_checkbox-border-color-hovered:var(--dss-border-disabled);
    --_checkbox-border-color-active:var(--dss-border-disabled);
    --_checkbox-bg-color-active:var(--dss-background-surface);
    &::after {
      content: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 5.75L4.83413 8.5L10.5 3' stroke='%23EBEEF4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
  }
}