.dss-tag{
  --_tag-padding-y:var(--dss-space-50);
  --_tag-padding-x:var(--dss-space-200);
  --_tag-border-radius:var(--dss-border-radius-full);
// --_tag-bg-color:var(--dss-background-primary);
--_tag-bg-color:var(--dss-background-fill-default);
--_tag-border-color:var(--dss-border-tertiary);
--_tag-bg-color-hovered:var(--dss-background-fill-default-hover);
--_tag-bg-color-pressed:var(--dss-background-fill-default-hover);
--_tag-font-color:var(--dss-text-primary);
// --_tag-font-size:var(--dss-font-size-300);
// --_tag-font-weight:var(--dss-font-weight-medium);
// --_tag-font-lineHeight:var(--dss-line-height-120);
--_tag-font-spacing:var(--dss-font-letter-spacing-normal);
--_tag-prefix-icon-size:var(--dss-size-400);
--_tag-prefix-icon-margin-right:var(--dss-space-100);
--_tag-counter-margin-left:var(--dss-space-150);
--_tag-close-margin-left:var(--dss-space-150);
--_tag-close-padding:var(--dss-space-100);
--_tag-close-border-radius:var(--dss-border-radius-full);
--_tag-close-bg-hovered:var(--dss-background-secondary);
// --_tag-font-textCase:none;
display: flex;
align-items: center;
justify-content: center;
padding: var(--_tag-padding-y) var(--_tag-padding-x);
background-color: var(--_tag-bg-color);
border: 1px solid var(--_tag-border-color);
cursor: pointer;
transition: all .4s;
border-radius: var(--_tag-border-radius);

&:hover{
  --_tag-bg-color:var(--_tag-bg-color-hovered)
}
&:focus{
  --_tag-bg-color:var(var(--_tag-bg-color-pressed))
}
  // &-label{
  //   font-size: var(--_tag-font-size);
  //   color: var(--_tag-font-color);
  //   font-weight: var(--_tag-font-weight);
  //   font-size: var(--_tag-font-size);
  //   line-height: var(--_tag-font-lineHeight);
  //   letter-spacing: var(--_tag-font-spacing); 
  // }
  &.disabled{
    --_tag-bg-color: var(--dss-background-disabled);
    --_tag-font-color:var(--dss-text-disabled);
    cursor: no-drop;
  }
  &-closable{
    --_tag-bg-color-hovered:var(--dss-background-primary) !important;
  }
  &-large{
    --_tag-padding-y:var(--dss-space-100);
    --_tag-padding-x:var(--dss-space-200);
    // --_tag-bg-color:var(--dss-background-primary);
    // --_tag-bg-color-hovered:var(--dss-background-secondary);
    // --_tag-bg-color-hovered:var(--dss-background-secondary);
    // --_tag-bg-color-pressed:var(--dss-background-secondary);
    // --_tag-font-color:var(--dss-text-primary);
    // --_tag-font-size:var(--dss-font-size-300);
    // --_tag-font-weight:var(--dss-font-weight-medium);
    // --_tag-font-lineHeight:var(--dss-line-height-120);
    // --_tag-font-spacing:var(--dss-font-letter-spacing-normal);
    // --_tag-prefix-icon-size:var(--dss-size-400);
    // --_tag-prefix-icon-margin-right:var(--dss-space-100);
    // --_tag-counter-margin-left:var(--dss-space-150);
    // --_tag-close-margin-left:var(--dss-space-150);
    // --_tag-close-padding:var(--dss-space-100);
    // --_tag-close-border-radius:var(--dss-border-radius-full);
    // --_tag-close-bg-hovered:var(--dss-background-secondary);
    // .dss-tag-label {
    //   --_tag-font-size:var(--dss-font-size-400);
    // }
  }

  &-small{
    --_tag-padding-y:var(--dss-space-50);
    --_tag-padding-x:var(--dss-space-150);
    // .dss-tag-label {
    //   --_tag-font-size:var(--dss-font-size-200);
    // }
  }
  .dss-prefix-icon{
    margin-right: var(--_tag-prefix-icon-margin-right);
    min-width: var(--dss-size-400);
    max-width: var(--dss-size-400);
    min-height: var(--dss-size-400);
    max-height: var(--dss-size-400);
    svg{
      width: var(--_tag-prefix-icon-size);
      height: var(--_tag-prefix-icon-size);
    }
  }
  .dss-tag-counter{
    margin-left: var(--_tag-counter-margin-left);
    color: var(--dss-text-secondary);
    // font-size: var(--_tag-font-size);
    // line-height: var(--_tag-font-lineHeight);
    // letter-spacing: var(--_tag-font-spacing);
    // font-weight: var(--_tag-font-weight);
  }
  .dss-tag-label {
    display: inline-flex;
    width: max-content;
  }
  .dss-close-icon{
    // margin-left: var(--_tag-close-margin-left);
    padding: var(--_tag-close-padding);
    margin-left: var(--dss-space-100);
    min-width: var(--dss-size-400);
    max-width: var(--dss-size-400);
    min-height: var(--dss-size-400);
    max-height: var(--dss-size-400);
    // border-radius: var(--_tag-close-border-radius);
    transition: all .4s;
    &:hover{
      background-color: var(--_tag-close-bg-hovered);
    }
  }
}