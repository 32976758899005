.tooltip-container {
  --_tooltip-bg: var(--dss-background-info);
  --_tooltip-radius: var(--dss-border-radius-200);
  --_tooltip-item-gap: var(--dss-space-50);
  --_tooltip-padding: var(--dss-space-300);
  --_tooltip-label-color: var(--dss-neutrals-100);
  --_tooltip-label-size: 12px;
  --_tooltip-label-weight: var(--dss-font-weight-regular);
  --_tooltip-label-line-height: 14.4px;
  --_tooltip-text-color: var(--dss-text-on-bg-fill);
  --_tooltip-text-size: var(--dss-font-size-200);
  --_tooltip-text-weight: var(--dss-font-weight-regular);
  --_tooltip-text-line-height: 19.5px;
  transition: all .35s;
  position: relative;
  display: inline-block;

  &:hover {
    .tooltip {
      visibility: visible;
    }
  }

  .tooltip {
    display: flex;
    flex-direction: column;
    gap: var(--_tooltip-item-gap);
    // visibility: hidden;
    visibility: visible;
    width: auto;
    // background-color: var(--_tooltip-bg);
    background-color: #0C0D10;
    border-radius: var(--_tooltip-radius);
    padding: var(--_tooltip-padding);
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    transition: visibility .4s;

    &-label {
      color: var(--_tooltip-label-color);
      font-size: var(--_tooltip-label-size);
      font-weight: var(--_tooltip-label-weight);
      line-height: var(--_tooltip-label-line-height);
    }

    &-text {
      color: var(--_tooltip-text-color);
      text-wrap: nowrap;
      font-size: var(--_tooltip-text-size);
      font-weight: var(--_tooltip-text-weight);
      line-height: var(--_tooltip-text-line-height);
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
      }
    }

    &-wrap {
      .tooltip-text {
        text-wrap: wrap !important;
        min-width: 247px;
      }
    }

    &-small {
      --_tooltip-padding: var(--dss-space-button-denser);
      --_tooltip-radius: var(--dss-border-radius-100);
      --_tooltip-item-gap: var(--dss-space-0);
      --_tooltip-text-size: 12px;
      --_tooltip-text-line-height: 18px;
    }
  }
}

.tooltip-container {
  .tooltip-top {
    bottom: 125%;
  }
  .tooltip-bottom {
    bottom: auto;
    .tooltip-text::after {
      top: auto;
      bottom: 100%;
      border-color: transparent transparent black transparent;
      }
  }
  .tooltip-right {
    top: auto;
    left: 110%;
    transform: translate(0, 70%);
    .tooltip-text::after {
      top: auto;
      left: -4px;
      border-color: transparent black transparent transparent;
      }
  }
  .tooltip-left {
    left: -110px;
    transform: translate(0, 70%);
    .tooltip-text::after {
      top: auto;
      left: auto;
      right: -9px;
      border-color: transparent transparent transparent black;
      }
  }
}


.tooltip-arrow-none {
  .tooltip-text::after {
      content: none;
    }
}
.tooltip-btm-lft {
  .tooltip-text::after {
      left: 25%;
    }
}
.tooltip-btm-center {
  .tooltip-text::after {
    left: 50%;
    }
}
.tooltip-btm-rgt {
  .tooltip-text::after {
    left: 75%;
    }
}

.tooltip-top-lft {
  .tooltip-text::after {
      left: 25%;
    }
}
.tooltip-top-center {
  .tooltip-text::after {
      left: 25%;
    }
}
.tooltip-top-rgt {
  .tooltip-text::after {
      left: 75%;
    }
}
.tooltip-top-rgt {
  .tooltip-text::after {
      left: 75%;
    }
}