.plt-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); // semi-transparent background
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100; // Make sure it's above all other content
}

.plt-modal-content {
  background: var(--plt-background-surface);
  // padding: var(--plt-space-600);
  border-radius: var(--plt-border-radius-300);
  // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  // width: 80%; // Adjust width as needed
  min-width: 495px;
  // min-width: auto;
  max-width: 800px; // Adjust max-width as needed
}

.plt-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--plt-space-400) var(--plt-space-600);

  // border-bottom: 1px solid var(--plt-border-secondary);
  // Other styling for header...
  // .plt-modal-header-title {
  //   font-size: var(--plt-heading-md-font-size);
  //   font-weight: var(--plt-heading-md-font-weight);
  //   line-height: var(--plt-heading-md-line-height);
  //   font-family: var(--plt-heading-md-font-family);
  // }
  .plt-iconbtn {
    background-color: var(--plt-background-button-ghost-primary);
  }
}

.plt-modal-close-icon {
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  background: transparent;
  transition: all 0.4s;
  cursor: pointer;

  &:hover {
    background: var(--plt-background-tertiary);
    border-radius: var(--plt-border-radius-100);
    transition: all 0.35s;
  }
}

.plt-modal-body {
  min-height: 100%;
  max-height: calc(100vh - 210px);
  overflow-x: hidden;
  overflow-y: auto;
  // padding: var(--plt-space-400);
  // padding-bottom: var(--plt-space-600);
  // Styling for modal body...
}

// .plt-select-dropdown-wrapper {
//   background-color: var(--plt-background-tertiary);
// }

.plt-select-dropdown {
  // fill: "border.neutral";
  background-color: var(--plt-background-surface);
  border-color: var(--plt-border-secondary);
  border-radius: var(--plt-border-radius-100);
  padding: var(--plt-space-200) var(--plt-space-400);
  // color: #101828 !important;
  width: 100%;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  cursor: pointer;
  // borderColor: "border.secondary";
  // borderRadius: "borderRadius.100";
  // borderWidth: "borderWidth.100";
}

.plt-preview {
  display: flex;
  justify-content: center;
  background: var(--plt-background-secondary);
  // border-radius: var(--plt-border-radius-300);
  padding: var(--plt-space-400) var(--plt-space-600);
  position: sticky;
  top: 0;
  z-index: 2;
}

.plt-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: var(--plt-space-300);
  padding: var(--plt-space-400) var(--plt-space-600);

  // border-top: 1px solid var(--plt-border-secondary);
  .cancel-btn {
    color: var(--plt-text-button-default-primary);
    padding: var(--plt-space-button-normal);
    border-radius: var(--plt-border-radius-150);
  }

  .apply-btn {
    color: var(--plt-text-button-fill-primary);
    background: var(--plt-background-button-fill-primary);
  }
}

.plt-custom-select {
  position: relative;
  display: inline-block;
  width: 100%;

  // .plt-select-dropdown {
  //   appearance: none;
  //   -webkit-appearance: none;
  //   -moz-appearance: none;
  //   background-color: #fff;
  //   border: 1px solid #ccc;
  //   padding: 10px 40px 10px 10px;
  //   width: 100%;
  //   font-size: 14px;
  //   cursor: pointer;
  // }

  // .plt-select-dropdown option {
  //   padding: 25px;
  //   background-color: tomato;

  //   &:hover &:focus &:only-child {
  //     background-color: yellow !important;
  //   }
  //   .item {
  //     background-color: aqua;
  //     &:hover &:focus {
  //       background-color: red;
  //     }
  //   }
  // }

  .plt-custom-select-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.plt-modal-body-content {

  // gap: 16px;
  // .plt-select-dropdown-wrapper  {
  //   min-width: 50%;
  //   max-width: 50%;
  // }
  .plt-select-dropdown-title {
    min-width: max-content;
    display: flex;
    align-items: center;
  }
}

.plt-modal-overlay {
  &.modal-tertiary {
    .plt-modal-footer {
      display: none;
    }
  }
}

// .plt-modal-overlay {
//  &.modal-secondary {
//   .plt-modal-header {
//     // padding-bottom: 0;
//     border-bottom: 0;
//   }
//   // .plt-modal-footer {
//   //   padding-top: 0;
//   //   border-top: 0;
//   // }
//  }
//  &.modal-tertiary{
//   .plt-modal-header{
//     padding: var(--plt-space-400) var(--plt-space-600);
//     border-bottom: 0;
//     &-title  {
//       font-size: var(--plt-heading-sm-font-size);
//       font-weight: var(--plt-heading-sm-font-weight);
//       line-height: var(--plt-heading-sm-line-height);
//       font-family: var(--plt-heading-sm--font-family);
//     }
//   }
//   .plt-modal-body {
//     padding: 0;
//   }
//   .plt-modal-body-content {
//     padding: 0 var(--plt-space-600) var(--plt-space-800) var(--plt-space-600);
//   }
//   .plt-token-details {
//     padding: 0 var(--plt-space-600);
//   }
//   .plt-select-dropdown-wrapper {
//     background: var(--plt-background-tertiary);
//     padding: var(--plt-space-400) var(--plt-space-600) 0 var(--plt-space-600);
//   }
//   .plt-modal-footer {
//     padding: var(--plt-space-500) var(--plt-space-600);
//   }
//  }
// }

.modal-value-wrapper,
.modal-textarea-wrapper,
.typo-modal-detail,
.elevation-modal-detail {
  padding: 0 var(--plt-space-600);
}

.plt-select-dropdown-wrapper {
  .modal-value-block {
    padding-bottom: 0 !important;
  }
}

.plt-dropdown-secondary {
  background-color: var(--plt-background-tertiary);
  // position: absolute;
  // z-index: 2;
  // width: 478px;
  // position: relative;

  .custom-dropdown {
    z-index: 3;
    border-right: var(--plt-border-width-100) solid var(--plt-border-secondary);

    .dropdown-header {
      padding: var(--plt-space-300) var(--plt-space-600);
      background-color: var(--plt-background-tertiary);
      border-color: transparent;
      border-radius: 0;
      font-size: var(--plt-ui-text-sm-medium-font-size);
      font-weight: var(--plt-ui-text-sm-medium-font-weight);
      line-height: var(--plt-ui-text-sm-medium-line-height);
      font-family: var(--plt-ui-text-sm-medium-font-family);

      &:hover {
        border-color: transparent;
        background-color: var(--plt-background-secondary);
      }

      &:focus {
        background-color: var(--plt-background-tertiary);
        // border-color: transparent;
      }
    }

    .dropdown-list {
      margin-top: var(--plt-space-100);
      background-color: var(--plt-background-tertiary);
      padding: 0;
      border: 0;

      .dropdown-item {
        padding: var(--plt-space-300) var(--plt-space-600);

        &:hover {
          background-color: var(--plt-background-primary);
        }
      }
    }
  }
}

.plt-dropdown-wrapper {
  min-width: 128px;
  // padding-bottom: 42px;
  width: 100%;

  &-common {
    min-width: 247px;
    max-width: 247px;
  }

  &-style {
    min-width: 412px;
    max-width: 412px;
  }

  &-icons {
    min-width: 147px;
    max-width: 147px;
    .custom-dropdown {
      min-width: 147px;
    }
  }

  &-font {
    // min-width: 247px;
    // max-width: 247px;
    padding-bottom: 0;
  }

  &-value {
    min-width: 202px;
    max-width: 202px;

    .custom-dropdown {
      min-width: 202px;
    }
  }

  &-shadow {
    min-width: 246px;
    max-width: 246px;
  }

  // &-scale {
  //   min-width: 160px;
  //   max-width: 160px;
  // }
  // &-fontsize {
  //   min-width: 80px;
  //   max-width: 80px;
  // }
  &-padding {
    min-width: 202px;
    max-width: 202px;
    padding-bottom: 42px;

    .custom-dropdown {
      min-width: 202px;
      max-width: 202px;
      position: absolute;
      z-index: 1;
    }
  }

  &-style-set {
    min-width: 445px;
    max-width: 445px;
    padding-bottom: 42px;

    .custom-dropdown {
      min-width: 445px;
      max-width: 445px;
      position: absolute;
      z-index: 1;
    }
  }
}

.plt-multi-dropdown-wrapper {
  min-width: 128px;
  width: 100%;
}

.plt-select-dropdown-conatiner {
  .plt-select-dropdown-wrapper {
    min-width: calc(50% - 20px);
    max-width: calc(50% - 20px);

    .css-1fdsijx-ValueContainer {
      padding: var(--plt-space-150) var(--plt-space-300);
    }

    .css-1nmdiq5-menu {
      border: 1px solid var(--plt-border-secondary);
      border-radius: var(--plt-border-radius-100);
      padding: var(--plt-space-200) 0;
      box-shadow: none;
    }
  }
}

// .css-1nmdiq5-menu{
//   top:100%;position:absolute;
//   width:100%;
//   z-index:1;
//   background-color:hsl(0, 0%, 100%);
//   border-radius:4px;
//   box-shadow:0 0 0 1px hsla(0, 0%, 0%, 0.1),0 4px 11px hsla(0, 0%, 0%, 0.1);
//   margin-bottom:8px;
//   margin-top:8px;
//   box-sizing:border-box;
// }

.upload-font-content {
  column-gap: var(--plt-space-1000);
}


.Toastify {
  .Toastify__toast-container {
    .Toastify__progress-bar--wrp {
      .Toastify__progress-bar--error {
        background: var(--plt-background-button-danger-primary);
      }

      .Toastify__progress-bar--success {
        background: var(--plt-background-button-fill-primary);
      }

    }

    .Toastify__toast--success {
      .Toastify__zoom-enter {
        svg {
          fill: var(--plt-background-button-fill-primary);
        }
      }
    }

    .Toastify__toast--error {
      .Toastify__zoom-enter {
        svg {
          fill: var(--plt-background-button-danger-primary);
        }
      }
    }
  }
}