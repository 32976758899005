// .plt-theme-pallette-lists {
//   display: flex;
//   flex-wrap: wrap;
//   margin-bottom: var(--plt-space-400);
// }

// .plt-theme-pallette-lists:not(:hover) .plt-theme-pallette-item:nth-child(6) {
//   -ms-flex-positive: 150;
//   flex-grow: 150
// }

// .plt-theme-pallette-lists:not(:hover) .plt-theme-pallette-item:nth-child(6) .plt-theme-pallette-text {
//   opacity: 1;
//   visibility: visible
// }

.plt-theme-pallette-lists {
  display: flex;
  max-width: 846px;
  // flex-wrap: wrap;

  // &:not(:hover) {
  //   .plt-theme-pallette-item {
  //     // &:nth-child(6) {
  //     //   flex-grow: 150;
  //     //   // min-width: max-content;
  //     //   // max-width: max-content;
  //     //   .plt-color-detail-block {
  //     //     opacity: 1;
  //     //     visibility: visible;
  //     //     // transition: all .4s
  //     //   }
  //     //   .plt-theme-pallette-text {
  //     //     opacity: 1;
  //     //     visibility: visible;
  //     //   }
  //     // }
  //   }
  // }

  .plt-theme-pallette-item {
    // --_plt-pallette-bg-color: red;
    --_plt-pallette-border-radius: 8px;
    --_plt-pallette-item-gap: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // gap: var(--_plt-pallette-item-gap);
    min-width: 0;
    // min-width: 89px;
    // flex: 1 1 0;
    flex: 10% 1 0;
    // background-color: var(--_plt-pallette-bg-color);
    padding: 23px 10px 23px 20px;
    border-radius: var(--_plt-pallette-border-radius) 0 0
      var(--_plt-pallette-border-radius);
    transition: all 0.4s;
    cursor: pointer;
    &.button {
      padding: 10px 10px 10px 20px;
      .plt-theme-pallette-text {
        font-size: var(--plt-body-xs-font-size);
      }
      // .plt-theme-pallette-text-info {
      //   font-size: var(--plt-body-xs-font-size);
      // }
    }

    &:not(:first-child) {
      margin-left: calc(-1 * var(--_plt-pallette-border-radius));
    }

    &:last-child {
      border-radius: 0 var(--_plt-pallette-border-radius)
        var(--_plt-pallette-border-radius) 0;
    }

    &:hover,
    &.active {
      flex-grow: 150;
      // min-width: max-content;
      // max-width: max-content;
      .plt-color-detail-block {
        opacity: 1;
        visibility: visible;
        // transition: all .4s
      }
      // .plt-theme-pallette-text {
      //   opacity: 1;
      //   visibility: visible;
      // }
    }

    // .dss-info-icon {
    //   margin-bottom: calc(var(--_plt-pallette-item-gap) + 4px);
    // }
  }
}

.plt-theme-pallette-lists {
  // .plt-theme-pallette-item {
  //   --dss-pallette-border-radius: 8px;
  //   --dss-pallette-item-gap: 8px;
  //   display: flex;
  //   -ms-flex-direction: column;
  //   flex-direction: column;
  //   -ms-flex-wrap: wrap;
  //   flex-wrap: wrap;
  //   gap: var(--dss-pallette-item-gap);
  //   min-width: 0;
  //   -ms-flex: 10% 1 0px;
  //   flex: 10% 1 0;
  //   padding: 12px 0;
  //   border-radius: var(--dss-pallette-border-radius) 0 0 var(--dss-pallette-border-radius);
  //   transition: all .4s;
  //   width: 90px;
  //   height: 120px;
  // }
  // .plt-theme-pallette-item:not(:first-child) {
  //   margin-left: calc(-1*var(--dss-pallette-border-radius))
  // }
  // .plt-theme-pallette-item:last-child {
  //   border-radius: 0 var(--dss-pallette-border-radius) var(--dss-pallette-border-radius) 0
  // }
  // .plt-theme-pallette-item:hover {
  //   -ms-flex-positive: 150;
  //   flex-grow: 150
  // }
  // .plt-theme-pallette-item:hover .plt-theme-pallette-text {
  //   opacity: 1;
  //   visibility: visible
  // }
  // .plt-theme-pallette-item .dss-info-icon {
  //   margin-bottom: calc(var(--dss-pallette-item-gap) + 4px)
  // }
  .plt-theme-pallette-text {
    display: inline-flex;
    align-items: center;
    // gap: 4px;
    font-size: var(--dss-font-size-200);
    color: var(--dss-text-on-bg-fill);
    font-weight: var(--dss-font-weight-regular);
    // line-height: 1.2;
    display: inline-block;
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    // opacity: 0;
    // visibility: hidden;
    // transition: all .4s
  }

  .plt-theme-pallette-copy-icon {
    display: flex;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .plt-theme-pallette-edit-icon {
    cursor: pointer;
  }
}

.plt-theme-pallette-lists .plt-theme-pallette-item-dark {
  .plt-theme-pallette-text {
    color: #000;
  }
  .plt-theme-pallette-copy-icon * {
    stroke: #000;
  }
  .plt-theme-pallette-edit-icon * {
    stroke: #000;
  }
}
