.radius-card-wrapper {
  .plt-common-card {
    .plt-common-card-img-box{
      border: 1px solid var(--plt-border-secondary);
      } 
      .dss-radius {
        &-small {
          border-radius: var(--dss-border-radius-150);
        }
        &-medium {
          border-radius: var(--dss-border-radius-400);
        }
        &-large {
          border-radius: var(--dss-border-radius-700);
        }
        &-full {
          border-radius: var(--dss-border-radius-full);
        }
      }
  }

    
}


.radius-modal-detail {
  // column-gap: 40px;
  align-items: flex-end;
  padding: 0 var(--plt-space-600);
  .plt-select-dropdown-wrapper {
    // flex: 1;
    max-width: 202px;
  }
}



