.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-input-container {
  width: 100%;
  margin-bottom: 20px;
}

.modal-title {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.crop-container {
  padding: 20px;

  position: relative;
  width: 100%;
  height: 400px;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.reactEasyCrop_Container {
  width: 100%;
  height: 100%;
  position: relative;
}