$layoutBp: (
  xs: phone-sm,
  sm: phone,
  md: tablet,
  lg: desktop
);

.row {
  --row-gutter: 24px;
  $grid-columns: 12;

  display: flex;
  flex-wrap: wrap;
  row-gap: var(--row-gutter);
  margin: 0 calc(-1 * var(--row-gutter) / 2);

  [class*="row-col"] {
    flex: 1 0 0%;
    min-width: 0;
    margin: 0 calc(var(--row-gutter) / 2);
  }

  @for $i from 1 through $grid-columns {
    .row-col-#{$i} {
      flex: 0 0 calc((100% / 12) * #{$i} - var(--row-gutter));
      max-width: calc((100% / 12) * #{$i} - var(--row-gutter));
    }
  }

  @each $prefix, $value in $layoutBp {
    @include mq($value, min) {
      @for $i from 1 through $grid-columns {
        .row-col-#{$prefix}-#{$i} {
          flex: 0 0 calc((100% / 12) * #{$i} - var(--row-gutter));
          max-width: calc((100% / 12) * #{$i} - var(--row-gutter));
        }
      }
    }
  }

  // @for $i from 1 through  $grid-columns {
  //   &-cols-#{$i} {
  //     .row-col {
  //       flex: 0 0 auto;
  //       width: calc((100% / #{$i}) - var(--row-gutter));
  //     }
  //   }
  // }

  &.row-gutter-8 {
    --row-gutter: 8px;
  }

  &.row-gutter-16 {
    --row-gutter: 16px;
  }

  &.row-gutter-20 {
    --row-gutter: 20px;
  }
}
