.chrome-picker {
  width: 324px !important;
  box-shadow: none !important;
}

.shades {
  .chrome-picker {
    box-shadow: none !important;
    .flexbox-fix {
      .hue-horizontal {
        display: none !important;
      }
    }
  }
}

.plt-color-detail-block {
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.plt-color-detail-block {
  display: flex;
  flex-direction: column;
  gap: var(--plt-space-200);
  // gap: var(--_plt-pallette-item-gap);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;

  // margin: auto;
  // .color-value {
  //   color: var(--plt-text-on-bg-fill);
  //   font-size: var(--plt-font-size-200);
  //   line-height: var(--plt-line-height-150);
  // }
  // .color-name {
  //   color: var(--plt-text-on-bg-fill);
  //   font-size: var(--plt-font-size-200);
  //   line-height: var(--plt-line-height-150);
  // }
}

.plt-color-picker-container {
  min-width: auto;
}

// .plt-modal-body-content {
//   .plt-color-picker-container {
//     min-width: 730px;
//   }
// }


// .plt-input-color {

// }

.change-token-modal {
  .plt-modal-content {
    min-width: 730px;
    max-width: calc(100vw - 300px);
  }
}
