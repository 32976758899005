.plt-profile-box {
  // margin-top: -40px;
  &-image {
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
  }
  
}

.plt-file-group {
  list-style: none;
  .plt-file-list {
    margin-top: var(--plt-space-300);
    .plt-member-img {
      min-width: var(--plt-size-600);
      max-width: var(--plt-size-600);
      min-height: var(--plt-size-600);
      max-height: var(--plt-size-600);
      img {
        min-width: var(--plt-size-600);
        max-width: var(--plt-size-600);
        min-height: var(--plt-size-600);
        max-height: var(--plt-size-600);
      }
  }
  }
}