@import "../../assets/scss/helpers/responsive";

.dss-textarea {
  --_textarea-wrapper-gap: var(--dss-space-100);
  --_textarea-label-color: var(--dss-text-form-secondary);
  --_textarea-label-font-weight: var(--dss-ui-text-form-label-normal-medium-font-weight);
  --_textarea-label-font-size: var(--dss-ui-text-form-label-normal-medium-font-size);
  --_textarea-label-line-height: var(--dss-ui-text-form-label-normal-medium-line-height);
  --_textarea-paddingX: var(--dss-space-300);
  --_textarea-paddingY: var(--dss-space-200);
  --_textarea-bg-color: var(--dss-background-form-fill-inactive);
  --_textarea-border-width: var(--dss-border-width-100);
  --_textarea-border-color: var(--dss-border-form-inactive);
  --_textarea-active-border-color: var(--dss-border-form-focused);
  --_textarea-border-radius: var(--dss-border-radius-100);
  --_textarea-placeholder-color: var(--dss-text-form-placeholder);
  --_textarea-placeholder-font-weight: var(--dss-ui-text-form-value-text-normal-regular-font-weight);
  --_textarea-placeholder-font-size: var(--dss-ui-text-form-value-text-normal-regular-font-size);
  --_input-validation-content-gap: var(--dss-space-100);
  --_textarea-text-color: var(--dss-text-primary);
  --_textarea-caret-color: var(--dss-background-inverse);
  --_textarea-cursor: text;
  --_textarea-validation-color: var(--dss-text-form-negative);
  --_textarea-validation-font-size: var(--dss-ui-text-form-validation-normal-regular-font-size);
  --_textarea-validation-line-height: var(--dss-ui-text-form-validation-normal-regular-line-height);
  --_textarea-validation-spacing: var(--dss-font-letter-spacing-normal);
  --_textarea-count-container-gap: var(--dss-space-200);

  --_textarea-count-text-color: var(--dss-text-tertiary);
  --_textarea-count-text-font-size: var(--dss-ui-text-sm-regular-font-size);
  --_textarea-count-text-font-weight: var(--dss-ui-text-sm-regular-font-weight);
  --_textarea-count-text-line-height: var(--dss-ui-text-sm-regular-line-height);
  --_textarea-count-text-spacing: var(--dss-font-letter-spacing-normal);

  &-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 494px;
    gap: var(--_textarea-wrapper-gap);
    overflow: auto;
  }

  &-label {
    color: var(--_textarea-label-color);
    text-transform: capitalize;
    font-weight: var(--_textarea-label-font-weight);
    font-size: var(--_textarea-label-font-size);
    line-height: var(--_textarea-label-line-height);

  }

  &-container {
    display: flex;
    flex-direction: column;
    min-height: 168px;
    gap: var(--_textarea-wrapper-gap);
    padding: var(--_textarea-paddingY) var(--_textarea-paddingX);
    background-color: var(--_textarea-bg-color);
    border: var(--_textarea-border-width) solid var(--_textarea-border-color);
    border-radius: var(--_textarea-border-radius);
    cursor: var(--_textarea-cursor);

    .dss-textarea-field {
      min-width: 469px;
      min-height: 144px;
      border: none;
      // background-color: var(--_textarea-bg-color);
      background-color:transparent;
      cursor: var(--_textarea-cursor);
      caret-color: var(--_textarea-caret-color);
      outline: none;
      color: var(--_textarea-text-color);
      font-weight: var(--_textarea-placeholder-font-weight);
      font-size: var(--_textarea-placeholder-font-size);
      resize: none;

      @include mq(desktop) {
        min-width: 330px;
      }

      @include mq(desktop-sm) {
        min-width: 300px;
      }

      &::placeholder {
        color: var(--_textarea-placeholder-color);
        font-weight: var(--_textarea-placeholder-font-weight);
        font-size: var(--_textarea-placeholder-font-size);
      }
    }

    &:hover {
      --_textarea-border-color: var(--dss-border-form-focused);
    }

    &.focused {
      --_input-border-color: var(--dss-border-form-focused);
      --_input-bg-color: var(--dss-background-form-fill-focused);
    }

    &.has-value {
      --_input-border-color: var(--dss-border-form-filled);
      --_input-bg-color: var(--dss-background-form-fill-surface);
    }

    &.error {
      --_textarea-border-color: var(--dss-border-form-negative);
    }

    .dss-tag {
      width: fit-content;
    }
  }

  &-fluid {
    min-width: 100%;
    --_textarea-label-color: var(--dss-text-tertiary);
  }

  &.disabled {
    --_textarea-label-color: var(--dss-text-form-disabled);
    --_textarea-border-color: var(--dss-border-form-disabled);
    --_textarea-active-border-color: var(--dss-border-form-disabled);
    --_textarea-bg-color: var(--dss-background-form-fill-disabled);
    --_textarea-prefix-color: var(--dss-text-form-disabled);
    --_textarea-cursor: no-drop;
    --_textarea-count-text-color: var(--dss-text-form-disabled);
    pointer-events: none;
  }

  &.read-only {
    --_textarea-bg-color: var(--dss-background-form-fill-readonly);
    --_textarea-border-color: var(--dss-border-form-inactive);
    --_textarea-active-border-color: var(--dss-border-form-inactive);
  }

  .dss-error-container {
    display: flex;
    align-items: center;
    gap: var(--_input-validation-content-gap);
    transform: translateY(-6px);
    opacity: 0;
    visibility: hidden;
    transition: all .4s;
    z-index: -1;

    &.active {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }

    .dss-error-message {
      color: var(--_textarea-validation-color);
      font-size: var(--_textarea-validation-font-size);
      letter-spacing: var(--_textarea-validation-spacing);
      line-height: var(--_textarea-validation-line-height);
    }
  }

  .dss-count-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--_textarea-count-container-gap);

    .dss-count-text {
      color: var(--_textarea-count-text-color);
      font-size: var(--_textarea-count-text-font-size);
      font-weight: var(--_textarea-count-text-font-weight);
      line-height: var(--_textarea-count-text-line-height);
      letter-spacing: var(--_textarea-count-text-spacing);
    }
  }

  &-sm {

  --_textarea-label-font-weight: var(--dss-ui-text-form-label-dense-medium-font-weight);
  --_textarea-label-font-size: var(--dss-ui-text-form-label-dense-medium-font-size);
  --_textarea-label-line-height: var(--dss-ui-text-form-label-dense-medium-line-height);
  --_textarea-placeholder-font-weight: var(--dss-ui-text-form-value-text-dense-regular-font-weight);
  --_textarea-placeholder-font-size: var(--dss-ui-text-form-value-text-dense-regular-font-size);
  --_textarea-validation-font-size: var(--dss-ui-text-form-validation-dense-regular-font-size);
  --_textarea-validation-line-height: var(--dss-ui-text-form-validation-dense-regular-line-height);
  --_textarea-count-text-font-size: var(--dss-ui-text-xs-regular-font-size);
  --_textarea-count-text-font-weight: var(--dss-ui-text-xs-regular-font-weight);
  --_textarea-count-text-line-height: var(--dss-ui-text-xs-regular-line-height);
    
  }

}