.sidebar-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 90px;
  max-width: 90px;
  background-color: var(--plt-text-primary);
  padding: var(--plt-space-600) var(--plt-space-200);
  z-index: 1;

  .sidebar-item.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
    max-height: 100%;
    gap: var(--plt-space-800);

    .sidebar-logo {
      cursor: pointer;
      min-width: 24px;
      max-width: 24px;
      min-height: 24px;
      max-height: 24px;
    }

    .sidebar-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--plt-space-600);
      min-width: 0;
      flex: 1 1 0;
      padding: 0 0 20px 0;
      overflow-y: auto;

      .sidebar-item {
        text-align: center;
        cursor: pointer;
        transition: all 0.35s;

        .logo {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 40px;
          max-width: 40px;
          min-height: 40px;
          max-height: 40px;
          border-radius: 50%;
          transition: all 0.35s;
        }

        &:hover {
          .logo {
            background-color: var(--dss-primary-800);
          }
        }

        &.active {
          .logo {
            background-color: var(--dss-primary-800);
          }
        }

        &:last-child {
          margin-top: auto;
        }

        // &:hover {
        //   .sidebar-title {
        //     color: var(--plt-background-selected);
        //   }
        // }

        // &.active {
        //   .sidebar-title {
        //     color: var(--plt-background-selected);
        //   }
        // }
      }

      .sidebar-title {
        color: var(--plt-text-on-bg-fill);
        transition: all 0.35s;
      }
    }

    // Dark mode styles
    // &.dark {
    //   background-color: #1f2937; // dark:bg-gray-900

    //   .icon {
    //     color: #9ca3af; // dark:text-gray-400

    //     &:hover {
    //       background-color: #374151; // dark:hover:bg-gray-800
    //     }

    //     &.active {
    //       color: #60a5fa; // dark:text-blue-400
    //       background-color: #1f2937; // dark:bg-gray-800
    //     }
    //   }
    // }
  }
}