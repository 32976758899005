.dss-toaster {
  --_toaster-paddingX: var(--dss-space-500);
  --_toaster-paddingY: var(--dss-space-400);
  --_toaster-border-radius: var(--dss-border-radius-200);
  --_toaster--bg-color: var(--dss-background-info);
  --_toaster-msg-container-gap: var(--dss-space-300);
  --_toaster-content-gap: var(--dss-space-300);
  --_toaster-msg-color: var(--dss-text-on-bg-fill);
  --_toaster-msg-font-weight: var(--dss-font-weight-regular);
  --_toaster-msg-font-size: var(--dss-font-size-400);
  --_toaster--close-size: var(--dss-space-800);
  --_toaster--close-bg-color: var(--dss-background-on-bg-hover);
  --_toaster--close-border-radius: var(--dss-border-radius-full);
  --_toaster-action-btn-bg: transparent;

  // position: fixed;
  // z-index: 1;
  // top: 40px;
  // right: 40px;
  display: flex;
  align-items: center;
  gap: var(--_toaster-content-gap);
  min-width: 336px;
  padding: var(--_toaster-paddingY) var(--_toaster-paddingX);
  border-radius: var(--_toaster-border-radius);
  background-color: var(--_toaster--bg-color);

  &-msg {
    color: var(--_toaster-msg-color);
    font-size: var(--_toaster-msg-font-size);
    font-weight: var(--_toaster-msg-font-weight);
  }

  &-msg-container {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    gap: var(--_toaster-msg-container-gap);
    width: 100%;
  }

  &-close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: var(--_toaster--close-size);
    min-height: var(--_toaster--close-size);
    background-color: var(--_toaster--close-bg-color);
    border-radius: var(--_toaster--close-border-radius);
    margin-left: auto;
    cursor: pointer;

  }

  &-action-footer {
    flex-direction: column;
    --_toaster-msg-container-gap: var(--dss-space-100);
  }
}