.header {
  padding: var(--plt-space-600);

  .user-info {
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
}

.main-logo {
  display: flex;
  align-items: center;
  .logo-link {
    text-decoration: none;
  }
  .logo-icon {
    min-width: 36px;
    max-width: 36px;
    min-height: 36px;
    max-height: 36px;
  }

  .logo-text {
    color: #000;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: bold;
    line-height: 1.5;
  }
}


.user-info-dropdown {
  .user-dropdown {
    min-width: 262px;
    padding: 0;
    border: none;
    border-radius: var(--plt-border-radius-100);
  }
  .divider {
    display: block;
    width: 100%;
    height: 1px;
    background: var(--plt-border-primary);
  }
  .profile-dropdown {
    .dropdown-list-block {
      cursor: pointer;
      transition: all 0.35s;
      &:hover {
        background-color: var(--plt-background-secondary);
      }
    }
    .profile-details {
      padding: var(--plt-space-400) var(--plt-space-600);
    }
    .profile-details-img  {
      min-width: var(--plt-size-800);
      max-width: var(--plt-size-800);
      min-height: var(--plt-size-800);
      max-height: var(--plt-size-800);
    }
    .profile-menu {
      padding: var(--plt-space-300) var(--plt-space-500);
      transition: all 0.35s;
    }
  }
  .domain-title {
    padding: var(--plt-space-100) var(--plt-space-600);
  }
  .domain-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .domain-account {
    padding: 0 var(--plt-space-600);
    &-img {
      min-width: var(--plt-space-800);
      max-width: var(--plt-space-800);
      min-height: var(--plt-space-800);
      max-height: var(--plt-space-800);
    }
  }
}
