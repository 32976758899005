.plt-btn-detail-set {
  display: flex;
  flex-direction: column;
  row-gap: var(--plt-space-300);
  .plt-btn-plt-detail-list {
    list-style: none;
    column-gap: var(--plt-space-200);
    align-items: baseline;
   
    span {
      width: fit-content;
    }
  }
}