.dss-empty-state-box {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--dss-space-1000);
  background: var(--dss-background-primary);
  border-color: var(--dss-border-primary);
  border-radius: var(--dss-border-radius-300);
  text-align: center;
  .dss-empty-state-img {
    min-width: 75px;
    max-width: 75px;
  }
}