


.plt-badge {
  // --_plt-badge-font-size: var(--plt-font-size-200);
  // --_plt-badge-font-color: var(--plt-text-secondary);
  // --_plt-badge-line-height: var(--plt-line-height-120);
  --_plt-badge-padding: var(--plt-space-50) var(--plt-space-100);
  --_plt-badge-border-radius: var(--plt-border-radius-150);
  // --_plt-badge-bg-color: var(--plt-background-neutral);
  // --_plt-badge-bg-color: var(--plt-background-tertiary);
  // --_plt-badge-bg-color: rgba(23, 23, 23, 0.05);
  --_plt-badge-bg-color: var(--plt-background-tertiary);

  display: inline-block;
  // font-size: var(--_plt-badge-font-size);
  // color: var(--_plt-badge-font-color);
  // line-height: var(--_plt-badge-line-height);
  padding: var(--_plt-badge-padding);
  border-radius: var(--_plt-badge-border-radius);
  background-color: var(--_plt-badge-bg-color);

  &-surface {
    --_plt-badge-bg-color: var(--plt-background-surface);
  }

  &-status {
  width: max-content;
  color: var(--plt-text-button-fill-primary);
  --_plt-badge-padding: 0 var(--plt-space-300);
  &.badgePrimary {
    --_plt-badge-bg-color: var(--plt-text-positive);
  }
  &.badgeNegative{
    --_plt-badge-bg-color: var(--plt-text-negative);
  }
}

}
