// .plt-dropdown-wrapper{
//   .plt-dropdown-title {
//   font-size: var(--plt-font-size-300);
//   font-weight: var(--plt-font-weight-500);
//   color: var(--plt-text-secondary);
//   line-height: var(--plt-line-height-120);
// }
// }
.plt-select-scale-wrapper {
  // padding-bottom: 130px;
  // padding-bottom: 42px;
  background-color: var(--plt-background-tertiary);
  .plt-dropdown-secondary {
    flex: 1;
    &:last-child {
      .custom-dropdown {
        border-right: 0;
      }
    }
  }
}

.plt-preview-group {
  .plt-preview {
    z-index: 2;
    position: unset;
    justify-content: space-between;
    align-items: flex-end;

    + .plt-preview {
      z-index: 2;
      border-top: 1px solid var(--plt-border-secondary);
    }
    .plt-font-preview {
      max-width: 425px;
      text-align: left;
    }
  }
}

.dropdown-btn {
  cursor: pointer;
  background: var(--plt-border-neutral);
  display: flex;
  padding: var(--plt-space-200) var(--plt-space-400);
  justify-content: center;
  align-items: center;
  border-radius: var(--plt-border-radius-full);
  border: 1px solid var(--plt-border-secondary);
  .dropdown-btn-txt {
    display: inline-block;
    font-size: var(--plt-font-size-200);
    color: #101828;
  }
  &-icon {
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
  }
}

.plt-value-btn {
  display: flex;
  align-items: center;
  // padding: var(--plt-space-200) var(--plt-space-400);
  // border-radius: var(--plt-border-radius-100);
  // border: 1px solid var(--plt-border-secondary);
  // background: var(--plt-border-neutral);
  .plt-value-btn-txt {
    color: var(--plt-text-primary);
  }
}

.modal-value-block {
  // display: inline-block;
  // padding-bottom: 42px;
  // position: relative;
  .input-suffix {
    position: absolute;
    right: var(--plt-space-300);
    top: 13px;
  }
  .input-field-custom {
    margin-top: 0;
  }
}

.modal-value-btn {
  width: 100%;
  // border-radius: var(--plt-border-radius-100);
  // border: 1px solid var(--plt-border-secondary);
  // padding: var(--plt-space-200) var(--plt-space-300);
}

.modal-textarea {
  min-height: 82px;
  background: var(--plt-background-surface);
  border-radius: var(--plt-border-radius-100);
  border: 1px solid var(--plt-border-primary);
  padding: var(--plt-space-200) var(--plt-space-300);
  margin-top: var(--plt-space-100);
  transition: all 0.4s;
  resize: none;
  outline: none;
  &::placeholder {
    color: var(--plt-text-placeholder);
    font-size: var(--plt-ui-text-sm-medium-font-size);
    font-weight: var(--plt-ui-text-sm-medium-font-weight);
    line-height: var(--plt-ui-text-sm-medium-line-height);
    font-family: var(--plt-ui-text-sm-medium-font-family);
  }
  &:hover {
    border: var(--plt-border-width-100) solid var(--plt-border-tertiary);
  }
  &:focus {
    border: var(--plt-border-width-100) solid var(--plt-text-tertiary);
  }
  &:focus-visible {
    border: var(--plt-border-width-100) solid var(--plt-text-tertiary);
  }
}

.modal-textarea-info {
  gap: var(--plt-space-200);
  .modal-textarea-desc {
    color: var(--plt-text-tertiary);
  }
}

.typo-modal-detail {
  max-width: 495px;
  // column-gap: 40px;
  flex-wrap: wrap;
  row-gap: var(--plt-space-500);
  .plt-select-dropdown-wrapper {
    min-width: calc(50% - 20px);
    max-width: calc(50% - 20px);
    .modal-value-block {
      padding-bottom: 42px;
    }
    .custom-dropdown {
      min-width: 202px;
    }
  }
}
