// Applied to the flex container
.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-row {
  flex-direction: row;
}

// default
.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.justify-content-start {
  justify-content: flex-start;
}

// default
.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.flex-align-stretch {
  align-items: stretch;
}

// default
.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: start;
}

.align-items-end {
  align-items: end;
}

.align-items-baseline {
  align-items: baseline;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

// default
.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

// fit element based on the available space
.flex-fit {
  min-width: 0;
  flex: 1 1 0%;
}

.w-full {
  width: 100%;
}
.w-50 {
  width: 50% !important;
}
.h-full {
  height: 100%;
}
.w-100vw {
  width: 100vw;
}
.h-100vh {
  height: 100vh;
}
.w-1-2 {
  width: 50%;
}

.w-fit {
  width: fit-content;
}
.h-fit {
  height: fit-content;
}

.m-auto {
  margin: 0 auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
