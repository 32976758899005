$black: #000;
$white: #fff;

// Basic grey
$grey-90: #1A1B21;
$grey-80: #1F2127;
$grey-70: #252730;
$grey-60: #343641;
$grey-50: #3A3C48;
$grey-40: #474954;
$grey-30: #4F515C;
$grey-20: #4F515C;
$grey-10: #636571;

// Orange
$orange-90: #612600;
$orange-80: #713107;
$orange-70: #803C0E;
$orange-60: #934817;
$orange-50: #A3541F;
$orange-40: #B46027;
$orange-30: #CB7032;
$orange-20: #DB7B3A;
$orange-10: #FE9A56;

// Teal
$teal-90: #005053;
$teal-80: #0E6366;
$teal-70: #076165;
$teal-60: #0B6A6E;
$teal-50: #2E8F92;
$teal-40: #3DA4A7;
$teal-30: #49B4B8;
$teal-20: #55C4C8;
$teal-10: #60D4D8;

// Keppel
$keppel-90: #004A37;
$keppel-80: #126450;
$keppel-70: #217862;
$keppel-60: #2D8972;
$keppel-50: #3DA087;
$keppel-40: #4AB298;
$keppel-30: #57C4A9;
$keppel-20: #65DABD;
$keppel-10: #78F4D5;

// Begonia
$begonia-90: #B4303C;
$begonia-80: #C0444F;
$begonia-70: #C8535D;
$begonia-60: #D06069;
$begonia-50: #D96F78;
$begonia-40: #E07C85;
$begonia-30: #E98A92;
$begonia-20: #F49EA5;
$begonia-10: #FEAEB5;

// Crayola
$crayola-90: #2F2095;
$crayola-80: #4637A6;
$crayola-70: #5749B3;
$crayola-60: #6557BE;
$crayola-50: #7062C6;
$crayola-40: #7E71D1;
$crayola-30: #8E81DD;
$crayola-20: #9D91E8;
$crayola-10: #B1A6F7;

// Mustard
$mustard-90: #775601;
$mustard-80: #8B6C1C;
$mustard-70: #9D7F34;
$mustard-60: #AB8F47;
$mustard-50: #BA9F5B;
$mustard-40: #C8AF6E;
$mustard-30: #D7C083;
$mustard-20: #EAD49B;
$mustard-10: #FEE9B5;
