@import "../../../assets/scss/helpers/responsive";

.plt-card-container {
  column-gap: 24px;
  row-gap: 40px;
  flex-wrap: wrap;

  .plt-card {
    max-width: 287px;
    min-width: 287px;
    padding: var(--plt-space-300);
    border-radius: var(--plt-border-radius-200);
    border: 1px solid transparent;
    transition: all 0.35s;
    cursor: pointer;
    // @include mq(desktop) {
    //   max-width: 270px;
    //   min-width: 270px;
    // }
    // @include mq(desktop) {
    //   max-width: 220px;
    //   min-width: 220px;
    // }
    // @include mq(tablet) {
    //   max-width: 100%;
    //   min-width: 100%;
    // }
    &.active {
      background: var(--plt-background-tertiary);
      border-color: var(--plt-border-secondary);
      transition: all 0.35s;
    }

    &:hover {
      background: var(--plt-background-tertiary);
      border-color: var(--plt-border-secondary);
      transition: all 0.35s;
    }

    .plt-card-desc {
      display: block;
    }
  }
  .plt-card-image {
    background: var(--plt-background-tertiary);
    border-radius: var(--plt-border-radius-200);
    overflow: hidden;
    img {
      max-width: 263px;
      min-width: 263px;
      min-height: 177px;
      max-height: 177px;
      object-fit: cover;
      // background-size: cover;
    }
  }

  &.plt-card-inside {
    column-gap: var(--plt-space-800);
    max-width: 925px;
    .plt-card {
      border-radius: var(--plt-border-radius-300);
      transition: all 0.35s;
      &:hover {
        border-color: transparent;
      }
    }
  }

  // .plt-card-body {

  // }
}
