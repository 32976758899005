.dss-spacing-size {
  background: var(--dss-semantics-informative-300);
  height: 28px;
}

.input-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 20%;
}

.input-field {
  padding-right: 20px;
  width: 100%;
  box-sizing: border-box;
}

.input-suffix {
  position: absolute;
  right: 10px;
  pointer-events: none;
}

.plt-scale-table.plt-scale-token-table {
  vertical-align: middle;
  td, tr, th, td {
    vertical-align: middle;
  }
  td {
    padding: 10px var(--plt-space-400);
  }
  .plt-iconbtn {
    opacity: 0;
    visibility: hidden;
  }
  tr:hover {
    .plt-iconbtn {
      opacity: 1;
      visibility: visible;
    }
  }
}
