

// .heading-table .heading-sample {
//   max-width: 246px;
//   // color: var(--plt-text-secondary);
//   // line-height: var(--plt-line-height-120);
//   font-weight: var(--plt-font-weight-bold);
// }


.plt-heading-usage {
  max-width: 152px;
  // font-size: var(--plt-font-size-200);
  // font-weight: var(--plt-font-weight-regular);
  // color: var(--plt-text-secondary);
  // line-height: var(--plt-line-height-120);
}

.plt-heading-values {
  display: flex;
  flex-direction: column;
  gap: var(--plt-space-200);
  // font-size: var(--plt-font-size-200);
  // font-weight: var(--plt-font-weight-regular);
  // color: var(--plt-text-secondary);
  // line-height: var(--plt-line-height-120);
  .plt-heading-info {
    display: block;
    // min-width: 172px;
    // font-size: var(--plt-font-size-200);
    // font-weight: var(--plt-font-weight-regular);
    // color: var(--plt-text-secondary);
    // line-height: var(--plt-line-height-120);
  }
}

// .heading-table {
//   tbody {
//     tr:nth-child(1) {
//       .heading-sample {
//         font-size: var(--plt-font-size-900);
//       }
//     }
//     tr:nth-child(2) {
//       .heading-sample {
//         font-size: var(--plt-font-size-800);
//       }
//     }
//     tr:nth-child(3) {
//       .heading-sample {
//         font-size: var(--plt-font-size-700);
//       }
//     }
//     tr:nth-child(4) {
//       .heading-sample {
//         font-size: var(--plt-font-size-600);
//       }
//     }
//     tr:nth-child(5) {
//       .heading-sample {
//         font-size: var(--plt-font-size-500);
//       }
//     }
//     tr:nth-child(6) {
//       .heading-sample {
//         font-size: var(--plt-font-size-400);
//       }
//     }
//     tr:nth-child(7) {
//       .heading-sample {
//         font-size: var(--plt-font-size-300);
//       }
//     }
//   }
// }
.plt-heading-values-wrapper,.plt-heading-values{
  transition: all .35s;
}
// .plt-heading-values {
//   transition: all .35s;
//   // max-width: 170px;
// }
// .preset-scales-icon {
//   display: inline-block;
//   transition: all .35s;
// }
.plt-table-hover-icon {
  //display: inline-block;
  opacity: 0;
  visibility: hidden;
  transition: all .35s;
}