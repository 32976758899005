.plt-token-contents-doc {
  font-family: "Arial", sans-serif;
  min-width: fit-content;
  padding-left: 14px;
  border-left: 1px solid #d9d9d9;

  .plt-token-contents-title {
    // color: var(--plt-text-primary);
    // font-size: var(--plt-font-size-200);
    // font-weight: var(--plt-font-weight-regular);
    // line-height: var(--plt-line-height-100);
    margin-bottom: 10px;
  }

  .plt-token-contents-lists {
    list-style: none;
    // padding: 0;

    .plt-token-contents-item {
      cursor: pointer;
      color: var(--plt-text-tertiary);
      margin-left: -15px;
      margin-bottom: var(--plt-space-150);
      padding: 3px 15px;
      transition: all 0.3s;
      border-left: 2px solid transparent;

      &:not(.active):hover {
        // background-color: #f0f0f0;
        color: var(--dss-primary-800);
        border-left: 2px solid var(--dss-primary-800);
        transition: 0.3s;
      }

      &.active {
        color: var(--dss-primary-800);
        border-left: 2px solid var(--dss-primary-800);
      }
    }
  }
}
