.verify-container {
  display: flex;
  justify-content: center;
  max-width: 454px;
  align-items: center;
  text-align: left;

  .title {
    width: 100%;
    text-align: left;
  }

  .description {
    width: 100%;
    text-align: left;
    .desc {
      color: var(--plt-text-primary);
    }
  }

  .content-des {
    width: 100%;
    text-align: left;
  }

  .resend {
    span {
      font-size: var(--plt-font-size-200);
    }
  }

}
