.dss-tab {
  --_tab-btn-gap: var(--dss-space-600);
  --_tab-btn-paddingX: var(--dss-space-100);
  --_tab-btn-paddingY: var(--dss-space-300);
  --_tab-btn-text-color: var(--dss-text-tertiary);
  // --_tab-btn-font-weight: var(--dss-font-weight-medium);
  // --_tab-btn-font-size: var(--dss-font-size-400);
  --_tab-btn-hovered-border-color: var(--dss-background-selected);
  --_tab-btn-active-border-height: var(--dss-space-50);
  --_tab-btn-active-border-color: transparent;
  --_tab-header-border-height: var(--dss-size-25);
  --_tab-header-border-color: var(--dss-border-primary);
  --_tab-btn-bg-color: transparent;
  --_tab-btn-border-radius: var(--dss-border-radius-0);
  --_tab-btn-icon-gap: var(--dss-space-200);
  --_tab-btn-badge-padding: var(--dss-space-50) var(--dss-space-200);
  --_tab-btn-badge-bg: var(--dss-background-primary);
  --_tab-btn-badge-border-radius: var(--dss-border-radius-full);
  --_tab-btn-badge-text-color: var(--dss-text-secondary);

  &-header {
    display: flex;
    align-items: center;
    gap: var(--_tab-btn-gap);
    border-bottom: var(--_tab-header-border-height) solid
      var(--_tab-header-border-color);
    &-pill {
      --_tab-btn-gap: var(--dss-space-400);
      --_tab-header-border-height: var(--dss-space-0);
      --_tab-header-border-color: transparent;
    }
  }

  &-btn {
    display: flex;
    align-items: center;
    gap: var(--_tab-btn-icon-gap);
    padding: var(--_tab-btn-paddingY) var(--_tab-btn-paddingX);
    color: var(--_tab-btn-text-color);
    // font-weight: var(--_tab-btn-font-weight);
    // font-size: var(--_tab-btn-font-size);
    cursor: pointer;
    border: none;
    border-bottom: var(--_tab-btn-active-border-height) solid
      var(--_tab-btn-active-border-color);
    background-color: var(--_tab-btn-bg-color);
    text-transform: capitalize;
    border-radius: var(--_tab-btn-border-radius);
    transition: all 0.4s;
    &:hover {
      --_tab-btn-active-border-color: var(--_tab-btn-hovered-border-color);
      --_tab-btn-text-color: var(--dss-text-primary);
    }
    &.active {
      --_tab-btn-active-border-color: var(--dss-background-selected);
      --_tab-btn-text-color: var(--dss-text-primary);
    }
    &-pill {
      --_tab-btn-border-radius: var(--dss-border-radius-200);
      --_tab-btn-paddingX: var(--dss-space-200);
      --_tab-btn-hovered-border-color: transparent;
      --_tab-btn-active-border-height: var(--dss-space-0);
      &.active {
        --_tab-btn-bg-color: var(--dss-background-primary);
        --_tab-btn-badge-bg: var(--dss-background-surface);
      }
      &:hover {
        --_tab-btn-bg-color: var(--dss-background-primary);
        --_tab-btn-badge-bg: var(--dss-background-surface);
      }
    }
    &-badge {
      padding: var(--_tab-btn-badge-padding);
      background-color: var(--_tab-btn-badge-bg);
      border-radius: var(--_tab-btn-badge-border-radius);
      color: var(--_tab-btn-badge-text-color);
    }
  }
  .prefix-icon {
    svg {
      path {
        transition: all 0.4s;
        stroke: var(--_tab-btn-text-color);
      }
      rect {
        transition: all 0.4s;
        stroke: var(--_tab-btn-text-color);
      }
    }
  }
  .suffix-icon {
    svg {
      path {
        transition: all 0.4s;
        stroke: var(--_tab-btn-text-color);
      }
    }
  }
  &-body {
    padding-top: 10px;
  }
}
