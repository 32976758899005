.payment-content{
  display: flex;
  flex-direction: column;
  gap: var(--plt-space-200);
  .plt-custom-radio-group .plt-radio-wrapper .plt-radio[type=radio]:checked + .plt-radio-label, .plt-custom-radio-group .plt-radio-wrapper .plt-radio[type=radio]:not(:checked) + .plt-radio-label {
    font-size: var(--plt-body-md-font-size);
  }
}
.payment-block {
  border: 1px solid var(--plt-border-secondary);
  border-radius: var(--plt-border-radius-300);
  max-width: 442px;

}

.payment-method-modal {
  .card-detail {
    column-gap: var(--plt-space-300);
  }
  .plt-modal-footer {
    display: none;
  }
  .radio-dropdown {
    // opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.35s;
    &.active {
      // opacity: 1;
      max-height: max-content;
    }
    .plt-modal-footer {
      display: flex;
    }
  }

  
}

