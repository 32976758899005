.ql-editor {
  font-family: 'Rethink Sans', sans-serif;
}

.ql-editor h1 {
  font-size: 32px;
  font-weight: bold;
}

.ql-editor h2 {
  font-size: 28px;
  font-weight: bold;
}

.ql-editor h3 {
  font-size: 24px;
  font-weight: bold;
}

.ql-editor h4 {
  font-size: 20px;
  font-weight: bold;
}

.ql-editor h5 {
  font-size: 18px;
  font-weight: bold;
}

.ql-editor h6 {
  font-size: 16px;
  font-weight: bold;
}

.editor-wrapper {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

.toolbar-grid {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.editor-container {
  min-height: 200px;
}

.html-preview {
  white-space: pre-wrap;
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #ddd;
}