.plt-character-box {
  width: 100%;
  background: var(--plt-background-tertiary);
  border-radius: var(--plt-border-radius-300);
}

// .plt-character-title {
//   font-size: var(--plt-font-size-800);
//   font-weight: var(--plt-font-weight-500);
//   line-height: var(--plt-line-height-120);
//   color: var(--plt-text-primary);
// }

// .plt-character-des {
//   font-size: var(--plt-font-size-200);
//   font-weight: var(--plt-font-weight-regular);
//   line-height: var(--plt-line-height-120);
//   color: var(--plt-text-secondary);
// }

.plt-text-underline {
  // font-size: var(--plt-font-size-200);
  // font-weight: var(--plt-font-weight-500);
  // line-height: var(--plt-line-height-120);
  // color: var(--plt-text-primary);
  text-decoration: underline;
  text-decoration: var(--plt-text-decoration-underline);
}