.plt-iconography-card-wrapper {
  flex-wrap: wrap;
  gap: var(--plt-space-200);
}

.plt-icon-card {
  min-width: 145px;
  max-width: max-content;
  border-radius: var(--plt-border-radius-300);
  background: var(--plt-background-tertiary);
}

.plt-dropdown-container {
  align-items: end;
}

.plt-input-search {
  min-width: 275px;
}

