
.plt-color-description {
      max-width: 140px;
}

.plt-color-column {
  min-width: 286px;
  max-width: 286px;
  .plt-color-box {
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    background: var(--plt-primary-1000);
    border-radius: var(--plt-border-radius-150);
  }
  .plt-color-detail {
    display: flex;
    flex-direction: column;
    gap: var(--plt-space-200);
    min-width: 166px;
  }
}

.plt-color-box {
  &.dss-purple {
    &-100 {
      background: var(--dss-primary-1000);
    }
    &-90 {
      background: var(--dss-primary-900);
    }
    &-80 {
      background: var(--dss-primary-800);
    }
    &-70 {
      background: var(--dss-primary-700);
    }
    &-60 {
      background: var(--dss-primary-600);
    }
    &-50 {
      background: var(--dss-primary-500);
    }
    &-40 {
      background: var(--dss-primary-400);
    }
    &-30 {
      background: var(--dss-primary-300);
    }
    &-20 {
      background: var(--dss-primary-200);
    }
    &-10 {
      background: var(--dss-primary-100);
    }
    &-1 {
      background: var(--dss-primary-100);
    }
  }
}