.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 418px;

  .info-msg  {
    max-width: 400px;
  }

  .error-msg {
    color: var(--plt-text-negative) !important;
    // font-size: calc(var(--plt-font-size-200) * 1px);
    // font-style: normal;
    // font-weight: 400;
    // line-height: var(--plt-line-height-130);
    margin-top: var(--plt-space-100);
    &-change {
      color: var(--plt-text-tertiary)
    }
    .plt-error-icon {
      min-width: 16px;
      max-width: 16px;
      min-height: 16px;
      max-height: 16px;
      display: inline-block;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  .eye-button {
    position: absolute;
    right: 8px;
    height: 100%;
    display: flex;
    top: 0;
    cursor: pointer;
  }

  // .input-label {
  //   font-style: normal;
  //   font-weight: 500;
  //   // font-family: "RethinkSans-Medium", sans-serif;
  //   // color: var(--plt-text-primary);
  //   // font-size: calc(var(--plt-font-size-300) * 1px);
  //   // line-height: var(--plt-line-height-120);
  //   /* 19.2px */
  // }





  // .label-text {
  //   color: var(--plt-text-primary);
  //   font-weight: var(--plt-font-weight-medium);
  //   line-height: var(--plt-line-height-120);
  // }
}


.text-desc {
  color: var(--plt-text-tertiary) !important;
  // font-size: calc(var(--plt-font-size-200) * 1px);
  // font-weight: var(--plt-font-weight-medium);
  // line-height: var(--plt-line-height-100);
}

.text-link {
  color: var(--plt-text-button-link-primary) !important;
  // font-family: "RethinkSans-SemiBold", sans-serif;
  // font-size: calc(var(--plt-font-size-100) * 1px);
  // font-weight: var(--plt-font-weight-500);
  // line-height: var(--plt-line-height-120);
  text-decoration: none;
  transition: all .35s;
  &:hover {
    text-decoration: underline;
  }
}